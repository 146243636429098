import { getCookie, setCookie } from "./cookies";

export class Notifications {

    /**
     * Whether notifications are allowed by browser settings
     */
    public static get isAllowed() {
        return window.Notification && window.Notification.permission === 'granted';
    }

    /**
     * Whether notifications are allowed and enabled by the user
     */
    public static get isEnabled(): boolean {
        return Notifications.isAllowed && getCookie('notify') === '1';
    }

    /**
     * Whether notificatoins are allowed and the user can enable them
     */
    public static get canEnable(): boolean {
        return window.Notification && window.Notification.permission !== 'denied';
    }

    /**
     * Enable notifications
     */
    public static enable() {
        if (Notifications.canEnable) {
            setCookie('notify', '1');
        }
    }

    /**
     * disable notifications
     */
    public static disable() {
        setCookie('notify', '0');
    }

    /**
     * Request that notifications be allowed and enabled by the user
     * @returns 
     */
    public static async requestAllow(): Promise<NotificationPermission> {
        if (!Notifications.canEnable) {
            return Promise.resolve('denied');
        }
        return await window.Notification.requestPermission();
    }
}