import { useEffect, useState } from 'react';
import { Choice } from '../../model/question';
import styles from './Spinner.module.scss';

export default function Spinner({ choices, winner }: {
    choices: Choice[],
    winner: number,
}) {
    const [spinnerValues, setSpinnerValues] = useState<Choice[]>([]);

    useEffect(() => {
        const vals = [];
        for (let idx = winner - 2; idx < winner + 11; idx++) {
            vals.push(choices[(idx + choices.length) % choices.length]);
        }
        setSpinnerValues(vals);
    }, [choices, winner]);

    return winner >= 0 ?
        <div className={styles.spinner}>
            {spinnerValues.map((val, idx) =>
                <div key={idx} className={[styles.option, idx !== 2 ? styles.notWinner : ''].join(' ')}>
                    <span>{val.text}</span>
                </div>)}
        </div> : null;
}