import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Question } from "../../model/question";
import styles from './MainCard.module.scss';

export default function MainCard() {
    const [questionText, setQuestionText] = useState<string>('');
    const [codeText, setCodeText] = useState<string>('');
    const navigate = useNavigate()

    const placeholders = [
        'Where should we go out to eat?',
        'What game should we play?',
        'Where should we go for spring break?',
        'Where should we go for lunch?',
        'Where should we go shopping today?',
        'What movie should we watch tonight?',
        'What concert should we go to?',
        'What should we make for dinner?',
        'Who\'s going to win the game tonight?',
        'Who\'s the best athlete of all time?',
        'What kind of car should we buy?',
        'What should be our class mascot?',
        'What should we make for dinner?',
        // 'What is the capital of Australia?',
        'Who is most likely to visit Mars?',
        'Who\'s place should we hang out at?',
        'Why did the chicken cross the road?',
        'When should we do a family reunion?',
    ]

    useEffect(() => {
        onTextChanged();  // initialize questionText in case field is not empty on load
        onCodeChanged();
        setPlaceholder();
    }, []);

    function setPlaceholder() {
        const textField = document.getElementById('questionText') as HTMLInputElement;
        if (textField) {
            const index = Math.floor(Math.random() * placeholders.length);
            textField.placeholder = `e.g. ${placeholders[index]}`;
        }
        setTimeout(setPlaceholder, 5000);
    }

    const onTextChanged = function () {
        const text = (document.getElementById('questionText') as HTMLInputElement)?.value.trim();
        setQuestionText(text);
    }

    const onCodeChanged = function () {
        const code = (document.getElementById('codeText') as HTMLInputElement)?.value.trim();
        setCodeText(code);
    }

    function keyUpQuestion(e: React.KeyboardEvent) {
        if (e.key === 'Enter') {
            submitQuestion();
        }
    }

    function keyUpCode(e: React.KeyboardEvent) {
        if (e.key === 'Enter') {
            submitCode();
        }
    }

    const submitQuestion = async function () {
        if (questionText) {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/questions`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                    body: JSON.stringify({ questionText: questionText }),
                });
            if (response.status !== 200) {
                throw new Error(response.statusText);
            }
            const respObj = await response.json();
            if (respObj.error) {
                throw new Error(respObj.error);
            }
            const question = respObj.question as Question;
            document.location = `/q/${question.code}`;  // todo: switch to navigate
        }
    }

    const submitCode = async function () {
        if (codeText.length === 6) {
            navigate(`/q/${codeText.toLowerCase()}`)
        }
    }

    return <div className={styles.mainCard}>
        <div className={styles.mainTitle}>
            <div>
                What do you <span>want to</span> <span><span>choose<span className={styles.super}>▛</span>e<span className={styles.sub}>▟</span></span> today?</span>
            </div>
        </div>
        <div className={styles.row}>
            <input className={styles.questionField}
                autoFocus
                autoComplete='one-time-code'
                type="text"
                id="questionText"
                maxLength={256}
                onFocus={e => e.target.select()}
                onChange={() => onTextChanged()}
                onKeyUp={keyUpQuestion} />
            <button className={styles.button}
                id="startButton"
                disabled={!questionText}
                onClick={submitQuestion}>
                Start!
            </button>
        </div>
        <div className={styles.row}>
            (Or enter your invitation code)
        </div>
        <div className={styles.row}>
            <input className={styles.codeField}
                autoComplete='one-time-code'
                type="text"
                id="codeText"
                maxLength={6}
                onFocus={e => e.target.select()}
                onChange={() => onCodeChanged()}
                onKeyUp={keyUpCode} />
            <button className={styles.button}
                type="button"
                id="codeButton"
                disabled={codeText.length !== 6}
                onClick={submitCode}>
                Go
            </button>
        </div>
    </div>
}