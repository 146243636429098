import { useEffect } from "react";
import { Choice, Place } from "../../model/question";
import PlaceCard from "./PlaceCard";

export default function PlaceList({ places, choices, active, placeButtonClicked, placeClicked }: {
    places: Place[],
    choices: Choice[] | undefined,
    active?: Place | undefined,
    placeButtonClicked: (place: Place) => void,
    placeClicked?: (place: Place) => void,
}) {
    useEffect(() => {
        if (active) {
            const elmt = document.getElementById(`place_${active.id}`);
            if (elmt) {
                elmt.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });
            }
        }
    }, [active]);

    return <div>
        {places.map(place => {
            const added = choices?.find(choice => choice.place?.id === place.id);
            return <div key={place.id} id={`place_${place.id}`}>
                <PlaceCard
                    place={place}
                    active={active?.id === place.id}
                    buttonText={added ? "Added" : "Add"}
                    buttonClicked={added ? undefined : placeButtonClicked}
                    cardClicked={() => placeClicked && placeClicked(place)}
                />
            </div>;
        })}
    </div >;
}