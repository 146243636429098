import { useEffect, useState } from 'react';
import { SelectionMethod } from '../../model/question';
import ToggleButtons from '../common/ToggleButtons';
import styles from './SelectionMethodToggle.module.scss';

export function SelectionMethodToggle({ method, onMethodChanged }: {
    method: SelectionMethod | undefined,
    onMethodChanged: (method: SelectionMethod) => any,
}) {
    const [methodDetails, setMethodDetails] = useState<JSX.Element | undefined>(undefined);

    useEffect(() => {
        switch (method) {
            case 'rank':
                setMethodDetails(
                    <span>
                        Winner will be determined through ranked-choice voting (
                        <a href="https://en.wikipedia.org/wiki/Instant-runoff_voting" target="_blank">instant runoff</a>).
                    </span>);
                break;
            case 'voting': setMethodDetails(<span>Winner will be determined based on votes and vetoes.</span>); break;
            case 'quiz': setMethodDetails(<span>Correct answer is pre-determined.</span>); break;
            case 'random': setMethodDetails(<span>Winner will be determined randomly.</span>); break;
            default: setMethodDetails(undefined); break;
        }
    }, [method]);

    if (!method) {
        method = 'rank';
    }

    return <div>
        <ToggleButtons
            active={method}
            setActive={active => onMethodChanged(active as SelectionMethod)}
            options={[
                {
                    key: 'rank',
                    label: 'Ranking',
                    imgSrc: '../../icons/up-down.svg',
                },
                {
                    key: 'voting',
                    label: 'Voting',
                    imgSrc: '../../icons/thumb-up-black.svg',
                },
                {
                    key: 'random',
                    label: 'Random',
                    imgSrc: '../../icons/random.svg',
                },
            ]}
        />
        <div className={styles.methodDetails}>
            {methodDetails}
        </div>
    </div >
}