import { useState } from 'react';
import Title from '../common/Title';
import HomeLayout from '../home/HomeLayout';
import HowItWorks from '../home/HowItWorks';
import UserActivity from '../home/UserActivity';
import styles from './Restaurant.module.scss';
import RestaurantMainCard from './RestaurantMainCard';

export default function Restaurant() {
  const [activityCardVisible, setActivityCardVisible] = useState<boolean>(false);

  return (
    <div className={styles.home}>
      <Title />
      <div className={styles.body}>
        <HomeLayout
          left={<RestaurantMainCard />}
          right={<UserActivity onOpen={() => setActivityCardVisible(true)} />}
          bottom={<HowItWorks />}
          rightVisible={activityCardVisible}
        />
      </div>
    </div >
  );
}