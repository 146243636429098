import styles from './Popup.module.scss';

export default function Popup({ children, hide, icon, caption }: {
    children: JSX.Element,
    hide: () => void,
    icon?: string,
    caption?: string,
}) {
    return <div
        className={styles.popupContainer}
        onClick={hide}>
        <div
            className={styles.popup}
            onClick={(e) => e.stopPropagation()}>
            <div className={styles.caption}>
                {icon ? <img src={icon} className={styles.icon}></img> : ""}
                <span className={styles.captionText}>{caption}</span>
                <span
                    className={styles.closeX}
                    onClick={hide}>
                    x
                </span>
            </div>
            <div className={styles.popupBody}>
                {children}
            </div>
        </div>
    </ div>
}