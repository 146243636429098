import { sha1 } from "crypto-hash";
import { getCookie } from "../services/cookies";

export type Question = {
    host: string,
    text: string,
    code: string,
    state?: QuestionState,
    selections: Selection[],
    participants: Participant[],
    lastUpdated: Date,
    timestamps: Timestamps,
    settings?: QuestionSettings,
}

export type QuestionState = 'nominating' | 'selecting' | 'results';

export type Selection = {
    active: boolean,
    choices: Choice[],
    settings: Settings,
    participants: ParticipantResult[],
    result?: Result,
}

export type Participant = {
    id: string,
    name?: string,
}

export type Choice = {
    text: string;
    place?: Place;
    user: string,
    correct?: boolean,
}

export type Place = Pick<google.maps.places.Place,
    'id' | 'displayName' | 'formattedAddress' | 'location' | 'rating' | 'userRatingCount' | 'priceLevel' | 'types' |
    'hasCurbsidePickup' | 'hasDelivery' | 'hasDineIn' | 'isReservable' | 'hasTakeout'>;

export type SelectionMethod = 'voting' | 'random' | 'rank' | 'quiz';

export type Settings = {
    method: SelectionMethod,
}

export type VoteSettings = Settings & {
    numVotes?: number,
    numVetoes?: number,
    liveResults?: boolean,
}

export type ParticipantResult = {
    user: string,
    submitted?: boolean,
}

export type VoteResult = ParticipantResult & {
    votes?: number[],
    vetoes?: number[],
}

export type RankResult = ParticipantResult & {
    rank: number[],
}

export type Result = {
    winners: number[],
    order: number[],
}

export type Timestamps = {
    created: Date,
    lastUpdated: Date,
    completed?: Date,
}

export type QuestionSettings = {
    type?: QuestionType,
    location?: QuestionLocation,
    radius?: number,
    unit?: 'miles' | 'kms',
}

export type QuestionLocation = Partial<Pick<google.maps.places.Place, 'id' | 'formattedAddress' | 'location'>>;

export type QuestionType = 'restaurant';

export function isVoteResult(result: ParticipantResult): result is VoteResult {
    return !!((result as VoteResult).votes || (result as VoteResult).vetoes);
}

export function isRankResult(result: ParticipantResult): result is RankResult {
    return !!(result as RankResult).rank;
}

export function getActiveSelection(question: Question): Selection | undefined {
    return question.selections.find(sel => sel.active);
}

export function countResults(selection: Selection, index: number, countFunc: (result: ParticipantResult) => boolean) {
    return selection.participants?.filter(result => result.submitted)
        .reduce((sum, result) => {
            if (countFunc(result)) {
                return sum + 1;
            } else {
                return sum;
            }
        }, 0);
}

export function getVotes(selection: Selection, index: number): number {
    return countResults(selection, index, result =>
        (result as VoteResult).votes?.includes(index) || false);
}

export function getVetoes(selection: Selection, index: number): number {
    return countResults(selection, index, result =>
        (result as VoteResult).vetoes?.includes(index) || false);
}

export function getPlace(selection: Selection, index: number, place: number): number {
    return countResults(selection, index, result =>
        (result as RankResult).rank?.indexOf(index) === place);
}

export function isHost(question: Question) {
    return question.host === getCookie('user');
}

export async function randomizeChoices(choices: Choice[], user: string, code: string): Promise<Choice[]> {
    const wrapper = await Promise.all(choices.map(async choice => {
        return {
            choice: choice,
            hash: await sha1(getCookie('user') + "_" + code + "_" + choice.text)
        }
    }));
    const sorted = wrapper
        .sort((a, b) => +(a.hash > b.hash) || -(a.hash < b.hash))
        .map(a => a.choice);
    return sorted;
}