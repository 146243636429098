import styles from './HowItWorks.module.scss';

export default function HowItWorks() {
    return <div className={styles.howItWorks}>
        <div className={styles.title}>
            How it works:
        </div>
        <div className={styles.step}>
            <div className={styles.stepLeft}>
                <div className={styles.number}>1</div>
                {/* <div className={styles.arrow} /> */}
            </div>
            <div>
                <div className={styles.header}>
                    Ask
                </div>
                <div className={styles.detail}>
                    {/* <div>
                        <img src="/images/question-mark.svg" className={styles.wiggle1} />
                    </div> */}
                    <span>
                        <p><span className={styles.emphasis}>Free and no login required! </span></p>
                        <p>Enter a question that you want to decide with your family, friends, class-mates, or co-workers, like:</p>
                        <ul>
                            <li>Where should we go for lunch?</li>
                            <li>Where should we go on our next family vacation?</li>
                            <li>What board game should we play?</li>
                        </ul>
                    </span>
                </div>
                <div className={styles.line}></div>
            </div>
        </div >
        <div className={styles.step}>
            <div className={styles.stepLeft}>
                <div className={styles.number}>2</div>
            </div>
            <div>
                <div className={styles.header}>
                    Invite
                </div>
                <div className={styles.detail}>
                    {/* <div>
                        <img src="/images/phone.svg" className={styles.wiggle2} />
                    </div> */}
                    <span>
                        <p>Send an invite by text, link, or invitation code so everyone can suggest options and vote.</p>
                        <p><span className={styles.emphasis}>It's a fun way to get the crew together!</span></p>
                    </span>
                </div>
                <div className={styles.line}></div>
            </div>
        </div>
        <div className={styles.step}>
            <div className={styles.stepLeft}>
                <div className={styles.number}>3</div>
            </div>
            <div>
                <div className={styles.header}>
                    Decide
                </div>
                <div className={styles.detail}>
                    {/* <div>
                        <img src="/images/high-five.svg" className={styles.wiggle3} />
                    </div> */}
                    <span>
                        <p>Select between different ways to choose the best option using voting, vetoing, ranked choice, and random selection.</p>
                        <p><span className={styles.emphasis}>Eliminate decision paralysis</span> and decide what works best for your group!</p>
                    </span>
                </div>
            </div>
        </div >
    </div >
}