import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Choice } from "../../model/question";
import { QuestionClient } from '../../services/questionClient';
import Popup from "../common/Popup";
import styles from './EditChoicePopup.module.scss';

export default function EditChoicePopup({ choice, caption, hide }: {
    choice?: Choice,
    caption?: string,
    hide: () => void,
    children?: never[],
}) {
    const { code } = useParams();
    const didUnmount = useRef(false);
    const { questionClient } = QuestionClient.useWebSocket(code, undefined, didUnmount.current);

    useEffect(() => {
        return () => {
            didUnmount.current = true;
        };
    }, []);

    function keyUp(e: React.KeyboardEvent) {
        if (e.key === 'Enter') {
            editChoice();
        }
    }

    function editChoice() {
        if (choice) {
            const value = (document.getElementById('textValue') as HTMLInputElement).value;
            if (value) {
                questionClient.editChoice(choice.text, value);
                hide();
            }
        }
    }

    function deleteChoice() {
        if (choice) {
            questionClient.deleteChoice(choice.text);
            hide();
        }
    }

    return choice
        ? <Popup
            hide={hide}
            caption={caption || 'Edit Suggestion'}>
            <div className={styles.scrollDiv}>
                <input type="text"
                    autoFocus
                    id="textValue"
                    onFocus={e => e.target.select()}
                    onKeyUp={keyUp}
                    defaultValue={choice.text} />
                <input type="button" value="Save" onClick={editChoice} />
                <img src="/icons/delete.svg" tabIndex={0} onClick={deleteChoice} />
            </div>
        </Popup >
        : null;
}