import styles from './HomeLayout.module.scss';

export default function HomeLayout({ left, right, bottom, rightVisible }: {
    left: JSX.Element,
    right: JSX.Element,
    bottom: JSX.Element,
    rightVisible: boolean,
}) {
    return (
        <div className={styles.body}>
            <div className={[styles.top, !rightVisible ? styles.center : ''].join(' ')}>
                {left}
            </div>
            <div className={styles.bottom}>
                <div className={styles.right}>
                    {right}
                </div>
                {bottom}
            </div>
        </div>
    );
}