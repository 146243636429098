// Given a cookie key `name`, returns the value of
// the cookie or `null`, if the key is not found.
export function getCookie(name: string): string | null {
    const nameLenPlus = (name.length + 1);
    return document.cookie
        .split(';')
        .map(c => c.trim())
        .filter(cookie => {
            return cookie.substring(0, nameLenPlus) === `${name}=`;
        })
        .map(cookie => {
            return decodeURIComponent(cookie.substring(nameLenPlus));
        })[0] || null;
}

export function setCookie(name: string, value: string) {
    const expire = new Date();
    expire.setFullYear(expire.getFullYear() + 1);
    document.cookie = `${name}=${value};expires=${expire.toUTCString()};path=/`;
}