import { useEffect, useRef, useState } from "react";
import { Participant, Question, QuestionState, Selection, SelectionMethod, getActiveSelection, isHost } from "../../model/question";
import { getCookie } from "../../services/cookies";
import styles from "./Group.module.scss";
import RenameParticipantPopup from "./RenameParticipantPopup";

export function Group({ question, connected, suggestionLabel }: {
    question: Question,
    connected: string[],
    suggestionLabel: string,
}) {
    const [user] = useState(getCookie('user'));
    const [name] = useState(getCookie('name') || '');
    const [selection, setSelection] = useState<Selection | undefined>(undefined);
    const [method, setMethod] = useState<SelectionMethod>('rank');
    const [state, setState] = useState<QuestionState | undefined>(undefined);
    const [me, setMe] = useState<Participant | undefined>(undefined);
    const [numUnnamed, setNumUnnamed] = useState<number>(0);
    const [renameParticipant, setRenameParticipant] = useState<Participant | undefined>(undefined);
    const promptForName = useRef(true);

    const shareEnabled = navigator.share !== undefined;

    useEffect(() => {
        setSelection(getActiveSelection(question));
        setState(question?.state ?? 'nominating');
        setNumUnnamed(question.participants.filter(participant =>
            participant.id !== user && !participant.name
        ).length);
        setMe(question.participants.find(part => part.id === user));

    }, [question]);

    useEffect(() => {
        if (me && promptForName.current) {
            if (!me.name && !name) {
                setRenameParticipant(me);
            }
            promptForName.current = false;
        }
    }, [me]);

    useEffect(() => {
        setMethod(selection?.settings.method || 'rank');
    }, [selection]);

    async function copyInvitation() {
        const address = document.location.origin + document.location.pathname;
        let message: string;
        switch (state) {
            case 'nominating': message = `Enter your ${suggestionLabel} here`; break;
            case 'selecting': message = 'Vote here'; break;
            case 'results': message = 'Here are the results'; break;
            default: message = '';
        }
        if (shareEnabled) {
            await navigator.share({
                title: 'Share invite to this question',
                text: `${question.text}\r\n${message}`,
                url: address,
            });
        } else {
            await navigator.clipboard.writeText(`${question.text}\r\n${message}: ${address}`);
            alert('Invitation message copied to clipboard');  // todo: nicer notification
        }
    }

    async function copyCode() {
        if (question) {
            await navigator.clipboard.writeText(question.code.toUpperCase());
            alert('Invitation code copied to clipboard');  // todo: nicer notification   
        }
    }

    function getName(participant: Participant) {
        return participant.name || 'Unnamed';
    }

    return <div className={styles.group}>
        <RenameParticipantPopup user={renameParticipant} hide={() => setRenameParticipant(undefined)} />
        {
            (method !== 'quiz' || (isHost(question) && state !== 'nominating')) &&
            <div className={styles.invitation}>
                <div>
                    <span>Invitation code:</span>
                    <a href="javascript: void(0)" className={styles.code}
                        onClick={copyCode}>{question.code}
                        <img /></a>
                </div>
                <span><a href="javascript: void(0)" onClick={copyInvitation}>{shareEnabled ? 'Share invitation' : 'Copy invitation'}</a></span>
            </div>
        }
        <div className={styles.contentHeader}>
            <div>Participants<span className={styles.numberTag}>{question.participants.length}</span></div>
            <div className={styles.numConnected}>
                {connected.length} connected
                <span className={[styles.separator, connected.length > 0 ? styles.connected : ''].join(' ')}> ●</span>
            </div>
        </div>
        <div className={styles.participants}>
            {me &&
                <span className={styles.participant}>
                    <span className={[styles.separator, connected.indexOf(me.id) > -1 ? styles.connected : ''].join(' ')}> ● </span>
                    {getName(me)}
                    <span> (<a href="javascript: void(0)" onClick={() => setRenameParticipant(me)}>you</a>)</span>
                </span>}
            {question.participants.length - numUnnamed < 8
                ? <>
                    {question.participants.filter(participant =>
                        participant.name && participant.id !== user
                    ).map((participant, index) =>
                        <span key={index} className={styles.participant}>
                            <span className={[styles.separator, connected.indexOf(participant.id) > -1 ? styles.connected : ''].join(' ')}> ● </span>
                            {getName(participant)}
                        </span>)}
                    {numUnnamed > 0 &&
                        <span className={[styles.participant, styles.others].join(' ')}>
                            <span className={[
                                styles.separator,
                                question.participants.find(part => !part.name && part.id !== user && connected.indexOf(part.id) > -1) ? styles.connected : '',
                            ].join(' ')}> ● </span>
                            {numUnnamed} unnamed
                        </span>}
                </>
                : <span className={[styles.participant, styles.others].join(' ')}>
                    <span className={[
                        styles.separator,
                        question.participants.find(part => part.id !== user && connected.indexOf(part.id) > -1) ? styles.connected : '',
                    ].join(' ')}> ● </span>
                    {question.participants.length - 1} others
                </span>
            }
        </div>
    </div >
}