import { useEffect, useId } from 'react';
import styles from './ThumbUpDownTotals.module.scss';

export function ThumbUpDownTotals({ up, down, centered }: {
    up: number,
    down: number,
    centered?: boolean,
}) {
    const upId = useId();
    const downId = useId();

    function getMargin(num: number) {
        // make sure total width <= 45
        if (num <= 3) return '-15px';   // 25 + (10 * 2) = 45
        if (num <= 4) return '-19px';   // 25 + (6 * 3) = 43
        if (num <= 5) return '-20px';   // 25 + (5 * 4) = 45
        if (num <= 6) return '-21px';   // 25 + (4 * 5) = 45
        return '-22px';                 // 25 + (3 * 6) = 43
    }

    useEffect(() => {
        const upElmt = document.getElementById(upId);
        const downElmt = document.getElementById(downId);
        upElmt?.style.setProperty('--upMargin', getMargin(up || 0));
        downElmt?.style.setProperty('--downMargin', getMargin(down || 0));
    }, [up, down]);

    return <div className={[styles.thumbUpDownTotals, centered ? styles.centered : ''].join(' ')}>
        {up > 0 && <span id={upId}>
            {[...Array(Math.min(up, 7))].map((_, i) => <img key={i} className={styles.upIcon} />)}
            <span>x {up}</span>
        </span>}
        {down > 0 && <span id={downId}>
            {[...Array(Math.min(down, 7))].map((_, i) => <img key={i} className={styles.downIcon} />)}
            <span>x {down}</span>
        </span>}
    </div>
}