import { useEffect, useRef, useState } from 'react';
import { Choice, ParticipantResult, Question, Selection, VoteResult, randomizeChoices } from '../../model/question';
import { getCookie } from '../../services/cookies';
import { QuestionClient } from '../../services/questionClient';
import styles from './Quiz.module.scss';
import { SubmitSelection } from './SubmitSelection';

export default function Quiz({ question }: {
    question: Question
}) {
    const [user, _] = useState(getCookie('user'));
    const [participant, setParticipant] = useState<ParticipantResult | undefined>(undefined);
    const [selection, setSelection] = useState<Selection | undefined>(undefined);
    const [votes, setVotes] = useState<number[]>([]);
    const [sortedChoices, setSortedChoices] = useState<Choice[]>([]);
    const didUnmount = useRef(false);
    const { questionClient } = QuestionClient.useWebSocket(question.code, undefined, didUnmount.current);

    useEffect(() => {
        return () => {
            didUnmount.current = true;
        };
    }, []);

    useEffect(() => {
        setSelection(question.selections.find(sel => sel.active));
    }, [question]);

    useEffect(() => {
        setParticipant(selection?.participants?.find(participant => participant.user === user));
    }, [selection, user]);

    useEffect(() => {
        if (selection) {
            const result = participant as VoteResult | undefined;
            if (selection.choices) {
                sortChoices(selection.choices);
                const storedVotes = result?.votes;
                if (storedVotes) {
                    setVotes(storedVotes);
                }
            } else {
                setVotes([]);
            }
        } else {
            setVotes([]);
        }
    }, [selection, participant]);

    async function sortChoices(choices: Choice[]) {
        setSortedChoices(await randomizeChoices(choices, user || '', question.code));
    }

    async function selectVote(index: number) {
        if (participant?.submitted) {
            return;
        }
        const choices: (number | undefined)[] = [];
        if (votes.indexOf(index) > -1) {
            // unselect vote
            choices.push(undefined, index);
        } else {
            if (votes.length === 1) {
                // unselect previous vote and select this one
                choices.push(index, votes[0]);
            } else if (votes.length === 0) {
                // select vote
                choices.push(index, undefined);
            } else {
                // do nothing
                return;
            }
        }
        questionClient.changeVote(choices[0], choices[1]);
    }

    function getVoteText(): string {
        if (participant?.submitted) {
            return 'Your answer has been recorded.';
        }
        if (votes.length === 0) {
            return 'Select your answer';
        } else {
            return 'Your answer is ready to submit.';
        }
    }

    return (
        <div className={styles.Vote}>
            {selection?.choices && <>
                <div className={styles.caption}>
                    <p>{getVoteText()}</p>
                </div>
                <SubmitSelection
                    participant={participant}
                    method={selection.settings.method}
                    voteText={'Answer'} />
                <div id={styles.options}>
                    {sortedChoices.map(choice => {
                        const index = selection?.choices.indexOf(choice);
                        const voteSelected = votes.indexOf(index) > -1;
                        const voteUnselected = participant?.submitted;
                        return <div key={choice.text} className={styles.optionWrapper}>
                            <div id={choice.text}
                                className={[
                                    styles.option,
                                    voteSelected ? styles.selected : voteUnselected ? styles.unselected : '',
                                ].join(' ')}
                                onClick={() => selectVote(index)}>
                                <img className={styles.voteIcon}
                                    onClick={() => selectVote(index)} />
                                <span>{choice.text}</span>
                            </div>
                        </div>
                    })}
                </div>
            </>}
        </div >
    );
}

