import { useEffect, useRef, useState } from 'react';
import { Question, isHost } from '../../model/question';
import { QuestionClient } from '../../services/questionClient';
import { Group } from './Group';
import styles from './QuestionCard.module.scss';
import { Settings } from './Settings';

export default function QuestionCard({ question, stateMessage, suggestionLabel, settingsChanged }: {
    question: Question,
    stateMessage: string,
    suggestionLabel: string[],
    settingsChanged: () => any,
}) {
    const [connected, setConnected] = useState<string[] | undefined>(undefined);
    const didUnmount = useRef(false);
    const { lastJsonMessage } = QuestionClient.useWebSocket(question.code, undefined, didUnmount.current);

    useEffect(() => {
        return () => {
            didUnmount.current = true;
        };
    }, []);

    useEffect(() => {
        if (lastJsonMessage) {
            if (lastJsonMessage.connected) {
                setConnected(lastJsonMessage.connected);
            }
        }
    }, [lastJsonMessage]);

    return question.state !== 'results' ?
        <div className={styles.card}>
            <Group question={question}
                connected={connected || []}
                suggestionLabel={suggestionLabel[1]} />
            {isHost(question) &&
                <Settings question={question} settingsChanged={settingsChanged} />}
        </div> : null;
}