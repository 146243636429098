import { useEffect, useRef, useState } from 'react';
import { Choice, ParticipantResult, Question, Selection, VoteResult, VoteSettings, randomizeChoices } from '../../model/question';
import { getCookie } from '../../services/cookies';
import { QuestionClient } from '../../services/questionClient';
import { SubmitSelection } from './SubmitSelection';
import styles from './Vote.module.scss';

export default function Vote({ question }: {
    question: Question
}) {
    const [user, _] = useState(getCookie('user'));
    const [participant, setParticipant] = useState<ParticipantResult | undefined>(undefined);
    const [selection, setSelection] = useState<Selection | undefined>(undefined);
    const [votes, setVotes] = useState<number[]>([]);
    const [vetoes, setVetoes] = useState<number[]>([]);
    const [numVotes, setNumVotes] = useState<number>(1);
    const [numVetoes, setNumVetoes] = useState<number>(0);
    const [sortedChoices, setSortedChoices] = useState<Choice[]>([]);
    const didUnmount = useRef(false);
    const { questionClient } = QuestionClient.useWebSocket(question.code, undefined, didUnmount.current);

    useEffect(() => {
        return () => {
            didUnmount.current = true;
        };
    }, []);

    useEffect(() => {
        setSelection(question.selections.find(sel => sel.active));
    }, [question]);

    useEffect(() => {
        setParticipant(selection?.participants?.find(participant => participant.user === user));
    }, [selection, user]);

    useEffect(() => {
        if (selection) {
            const result = participant as VoteResult | undefined;
            if (selection.choices) {
                sortChoices(selection.choices);
                const storedVotes = result?.votes;
                if (storedVotes) {
                    setVotes(storedVotes);
                }
                const storedVetoes = result?.vetoes;
                if (storedVetoes) {
                    setVetoes(storedVetoes);
                }
            } else {
                setVotes([]);
                setVetoes([]);
            }
            const voteSettings = selection.settings as VoteSettings;
            setNumVotes(voteSettings.numVotes || 1);
            setNumVetoes(voteSettings.numVetoes || 0);
        } else {
            setVotes([]);
            setVetoes([]);
        }
    }, [selection, participant]);

    async function sortChoices(choices: Choice[]) {
        setSortedChoices(await randomizeChoices(choices, user || '', question.code));
    }

    async function selectVote(index: number) {
        if (participant?.submitted) {
            return;
        }
        const choices: (number | undefined)[] = [];
        if (votes.indexOf(index) > -1) {
            // unselect vote
            choices.push(undefined, index);
        } else {
            if (numVotes === 1 && votes.length === 1) {
                // unselect previous vote and select this one
                choices.push(index, votes[0]);
            } else if (votes.length < numVotes) {
                // select vote
                choices.push(index, undefined);
            } else {
                // do nothing
                return;
            }
        }
        questionClient.changeVote(choices[0], choices[1]);
        if (choices[0] !== undefined && vetoes.indexOf(choices[0]) > -1) {
            await selectVeto(choices[0]);
        }
    }

    async function selectVeto(index: number) {
        if (participant?.submitted) {
            return;
        }
        const choices: (number | undefined)[] = [];
        if (vetoes.indexOf(index) > -1) {
            // unselect veto
            choices.push(undefined, index);
        } else {
            if (numVetoes === 1 && vetoes.length === 1) {
                // unselect previous veto and select this one
                choices.push(index, vetoes[0]);
            } else if (vetoes.length < numVetoes) {
                // select vote
                choices.push(index, undefined);
            } else {
                // do nothing
                return;
            }
        }
        questionClient.changeVeto(choices[0], choices[1]);
        if (choices[0] !== undefined && votes.indexOf(choices[0]) > -1) {
            await selectVote(choices[0]);
        }
    }

    function getVoteText(): string {
        if (numVotes === 1) {
            if (participant?.submitted) {
                return 'Your vote has been recorded.';
            }
            if (votes.length === 0) {
                if (numVetoes === 0) {
                    return 'Select your vote.';
                } else {
                    return `Select your vote and up to ${numVetoes} veto${numVetoes > 1 ? 'es.' : '.'}`;
                }
            } else {
                return 'Your vote is ready to submit.';
            }
        } else {
            if (participant?.submitted) {
                return 'Your votes have been recorded.';
            }
            if (votes.length === 0) {
                if (numVetoes === 0) {
                    return `Select up to ${numVotes} votes.`;
                } else {
                    return `Select up to ${numVotes} votes and ${numVetoes} veto${numVetoes !== 1 ? 'es.' : '.'}`;
                }
            } else if (votes.length === numVotes) {
                return 'Your votes are ready to submit.';
            } else {
                if (numVetoes === 0) {
                    return `${numVotes - votes.length} vote${(numVotes - votes.length) > 1 ? 's' : ''} remaining.`;
                } else {
                    return `${numVotes - votes.length} vote${(numVotes - votes.length) > 1 ? 's' : ''} `
                        + `and ${numVetoes - vetoes.length} veto${(numVetoes - vetoes.length) !== 1 ? 'es' : ''} remaining.`;
                }
            }
        }
    }

    return (
        <div className={styles.vote}>
            {selection?.choices && <>
                <div className={styles.caption}>
                    {getVoteText()}
                </div>
                <SubmitSelection
                    participant={participant}
                    method={selection.settings.method} />
                <div id={styles.options}>
                    {sortedChoices.map(choice => {
                        const index = selection?.choices.indexOf(choice);
                        const voteSelected = votes.indexOf(index) > -1;
                        const voteUnselected = participant?.submitted || numVotes > 1 && votes.length >= numVotes;
                        const vetoSelected = vetoes.indexOf(index) > -1;
                        const vetoUnselected = participant?.submitted || numVetoes > 1 && vetoes.length >= numVetoes;
                        return <div key={choice.text} className={styles.optionWrapper}>
                            <div id={choice.text}
                                className={[
                                    styles.option,
                                    voteSelected ? styles.selected : voteUnselected ? styles.unselected : '',
                                    vetoSelected ? styles.vetoSelected : vetoUnselected ? styles.vetoUnselected : '',
                                ].join(' ')}
                                onClick={() => numVetoes === 0 && selectVote(index)}>
                                <img className={styles.voteIcon}
                                    onClick={() => numVetoes > 0 && selectVote(index)} />
                                <span>{choice.text}</span>
                                <div className={styles.votes}>
                                    {/* {liveResults &&
                                        <ThumbUpDownTotals up={allVotes[index]} down={allVetoes[index]} />} */}
                                </div>
                                {numVetoes > 0 && <img className={styles.vetoIcon} onClick={() => selectVeto(index)} />}
                            </div>
                        </div>
                    })}
                </div>
            </>}
        </div >
    );
}

