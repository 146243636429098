import { Fragment, useEffect, useState } from "react";
import { Choice, Question, Selection, getPlace, getVetoes, getVotes } from "../../model/question";
import styles from './AllResultsList.module.scss';
import { RandomCheckmark } from "./RandomCheckmark";
import { RankTotals } from "./RankTotals";
import { ThumbUpDownTotals } from "./ThumbUpDownTotals";
import VoteBar from "./VoteBar";

export default function AllResultsList({ question }: {
    question: Question
}) {
    const [index, setIndex] = useState<number>(question.selections.findIndex(sel => sel.active));
    const [selection, setSelection] = useState<Selection>();
    const [sortedChoices, setSortedChoices] = useState<Choice[]>([]);

    useEffect(() => {
        setSelection(question.selections[index]);
    }, [index]);

    useEffect(() => {
        if (selection?.choices) {
            if (selection?.result?.order) {
                setSortedChoices(selection.result.order.map(index => selection.choices[index]));
            } else {
                setSortedChoices([]);
            }
        }
    }, [selection]);

    return selection ?
        <div className={styles.contentBox}>
            <div className={styles.header}>
                All results:
                {question.selections.length > 1 &&
                    <span className={styles.rounds}> rounds:{
                        question.selections.map((sel, idx) =>
                            <Fragment key={idx}>
                                &nbsp;
                                {idx !== index
                                    ? <a href="javascript: void(0)" onClick={() => setIndex(idx)}>{idx + 1}</a>
                                    : <span className={styles.selected}>{idx + 1}</span>}
                                {idx < question.selections.length - 1 && <> |</>}
                            </Fragment>)
                    }</span>}
            </div>
            {sortedChoices.map(choice => {
                const index = selection.choices?.findIndex(c => c.text === choice.text);
                return <Fragment key={choice.text}>
                    <div key={choice.text} className={styles.option}>
                        <span>{choice.text}</span>
                        {selection?.settings.method === 'voting' &&
                            < ThumbUpDownTotals
                                up={getVotes(selection, index)}
                                down={getVetoes(selection, index)} />}
                        {selection?.settings.method === 'rank' &&
                            < RankTotals
                                first={getPlace(selection, index, 0)}
                                second={getPlace(selection, index, 1)}
                                third={getPlace(selection, index, 2)} />}
                        {selection?.settings.method === 'random' && index === selection.result?.winners[0] &&
                            <RandomCheckmark />
                        }
                        {/* {selection?.settings.method === 'quiz' &&
                            <QuizTotals
                                answers={getVotes(selection, index)}
                                total={allVotes} />} */}
                    </div>
                    {selection.settings.method === 'voting' &&
                        <VoteBar votes={getVotes(selection, index)}
                            vetoes={getVetoes(selection, index)}
                            numParticipants={selection.participants.filter(p => p.submitted).length} />}
                </Fragment>
            })}
        </div> : null;
}