import styles from './TextWithTooltip.module.scss';

export default function TextWithTooltip({ text, tooltip }: {
    text: string,
    tooltip: string | (() => string),
}) {
    return <span className={styles.wrapper}>
        <span className={styles.text}
            data-tooltip-id='tooltip'
            data-tooltip-content={typeof tooltip === 'function' ? tooltip() : tooltip}>
            {text}
        </span>
    </span>;
}