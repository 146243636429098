import { useCallback, useRef } from 'react';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import styles from './CollaborateLayout.module.scss';


export default function CollaborateLayout({ top, left, right, bottom }: {
    top: JSX.Element,
    left?: JSX.Element,
    right: JSX.Element,
    bottom: JSX.Element,
}) {
    const layoutDiv = useRef<HTMLDivElement>(null);
    const leftDiv = useCallback((node: HTMLDivElement) => observeAndSetHeight(node, '--leftHeight'), []);
    const rightDiv = useCallback((node: HTMLDivElement) => observeAndSetHeight(node, '--rightHeight'), []);

    function observeAndSetHeight(node: HTMLDivElement, property: string) {
        if (node) {
            const observer = new ResizeObserver(() => {
                if (node && layoutDiv.current) {
                    const height = node.getBoundingClientRect().height;
                    layoutDiv.current.style.setProperty(property, height + 'px');
                }
            });
            observer.observe(node);
        }
    }

    return <div>
        <div className={styles.top}>
            {top}
        </div>
        <div ref={layoutDiv} className={styles.layout}>
            {left &&
                <div ref={leftDiv} className={styles.left}>
                    {left}
                </div>}
            <div ref={rightDiv} className={styles.right}>
                <div className={styles.rightTop}>
                    {right}
                </div>
                <div className={styles.bottom}>
                    {bottom}
                </div>
            </div>
        </div>
        <Tooltip id="tooltip" className={styles.tooltip} />
    </div>
}