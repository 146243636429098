import { useEffect, useState } from "react";
import { QuestionLink } from "../../model/questionLink";
import { getCookie } from "../../services/cookies";
import ToggleButtons from "../common/ToggleButtons";
import styles from "./UserActivity.module.scss";

export default function UserActivity({ onOpen }: { onOpen: () => any }) {
    const [user] = useState(getCookie('user'));
    const [name] = useState(getCookie('name'));
    const [active, setActive] = useState<QuestionLink[]>([]);
    const [recent, setRecent] = useState<QuestionLink[]>([]);
    const [hasActivity, setHasActivity] = useState<boolean>(false);
    const [openSection, setOpenSection] = useState<'active' | 'recent'>('active');

    useEffect(() => {
        if (user) {
            loadQuestions();
        }
    }, [user]);

    useEffect(() => {
        setHasActivity(active.length > 0 || recent.length > 0);
    }, [active, recent]);

    useEffect(() => {
        if (hasActivity) {
            onOpen();
        }
    }, [hasActivity]);

    async function loadQuestions() {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/questionsForUser`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });
        if (response.status === 200) {
            const respObj = await response.json();
            setActive(respObj.active as QuestionLink[]);
            setRecent(respObj.recent as QuestionLink[]);
        }
    }

    return (user && hasActivity) ?
        <div className={styles.card}>
            <div className={styles.cardTitle}>
                Welcome back{name &&
                    <>, <span>{name}</span></>}
                !
            </div>
            <ToggleButtons active={openSection}
                setActive={active => setOpenSection(active as 'recent' | 'active')}
                options={[
                    { key: 'active', label: 'Active', tag: active.length.toString() },
                    { key: 'recent', label: 'Recent', tag: recent.length.toString() },
                ]} />
            {openSection === 'active' &&
                <div className={styles.questionList}>
                    {active.length === 0 &&
                        <span>No active questions</span>}
                    {active.length > 0 &&
                        <ul>
                            {active.map(link =>
                                <li key={link.code}><a href={`/q/${link.code}`}>{link.text}</a></li>
                            )}
                        </ul>}
                </div>}
            {openSection === 'recent' &&
                <div className={styles.questionList}>
                    {recent.length === 0 &&
                        <span>No recently completed questions</span>}
                    {recent.length > 0 &&
                        <ul>
                            {recent.map(link =>
                                <li key={link.code}><a href={`/q/${link.code}`}>{link.text}</a></li>
                            )}
                        </ul>}
                </div>}
        </div>
        : null
}
