import Pill from "./Pill";
import styles from './SelectablePill.module.scss';

export default function SelectablePill({ content, selected, onSelectionChanged }: {
    content: string | JSX.Element,
    selected: boolean,
    onSelectionChanged: (selected: boolean) => void,
}) {
    return <span className={styles.wrapper} onClick={() => onSelectionChanged(!selected)}>
        <Pill content={content} className={selected ? styles.selected : styles.unselected} />
    </span>
}