import useWebSocket from "react-use-websocket";
import { SendJsonMessage, WebSocketHook } from "react-use-websocket/dist/lib/types";
import { WsReponse } from "../model/WsResponse";
import { Place, SelectionMethod, Settings } from "../model/question";
import { getCookie } from "./cookies";

export class QuestionClient {
    public constructor(private sendJsonMessage: SendJsonMessage) { }

    public static useWebSocket(code: string | undefined, error?: string | undefined, didUnmount?: boolean) {
        const url = `${process.env.REACT_APP_WEBSOCKET_URL}/questions/${code}`;
        const ws = useWebSocket<WsReponse>(url, {
            share: true,
            shouldReconnect: () => {
                return !error && !didUnmount;
            },
            heartbeat: true,
            onOpen: () => {
                const wsAsAny = ws.getWebSocket() as any;
                if (!wsAsAny.timeoutId) {
                    const timeoutId = setTimeout(() => {
                        const user = getCookie('user');
                        const token = getCookie('token');
                        ws.sendJsonMessage({
                            user: user,
                            token: token,
                            action: 'setUser',
                        });
                    }, 0);
                    // onOpen is called multiple times for the same connection. Only call setUser once
                    wsAsAny.timeoutId = timeoutId;
                }
            },
            onMessage: () => {
                // once we've received the first message, we know setUser has been completed called
                // this indicates it's safe for other messages to go through
                (ws.getWebSocket() as any).userSet = true;
            },
        });
        return {
            ...ws,
            questionClient: new QuestionClient(QuestionClient.sendMessageWhenReady.bind(null, ws))
        };
    }

    private static sendMessageWhenReady(ws: WebSocketHook, obj: unknown) {
        if (ws.readyState === 1 && (ws.getWebSocket() as any)?.userSet) {
            ws.sendJsonMessage(obj);
        } else {
            // try again later
            setTimeout(() => QuestionClient.sendMessageWhenReady(ws, obj), 100);
        }
    }

    public renameParticipant = ((name: string) => {
        this.sendJsonMessage({
            user: getCookie('user'),
            action: 'renameParticipant',
            name: name,
        });
    }).bind(this);

    public addChoice = ((choice: string, place?: Place) => {
        this.sendJsonMessage({
            user: getCookie('user'),
            place: place,
            action: 'addChoice',
            choice: choice,
        });
    }).bind(this);

    public editChoice = ((oldText: string, newText: string) => {
        this.sendJsonMessage({
            user: getCookie('user'),
            action: 'editChoice',
            oldText: oldText,
            newText: newText,
        });
    }).bind(this);

    public deleteChoice = ((choice: string) => {
        this.sendJsonMessage({
            user: getCookie('user'),
            action: 'deleteChoice',
            choice: choice,
        });
    }).bind(this);

    public markChoiceCorrect = ((choice: string) => {
        this.sendJsonMessage({
            user: getCookie('user'),
            action: 'markChoiceCorrect',
            choice: choice,
        });
    }).bind(this);

    public settingsChanged = ((method: string, settings: Settings) => {
        this.sendJsonMessage({
            user: getCookie('user'),
            action: 'saveSettings',
            settings: settings,
        });
    }).bind(this);

    public changeVote = ((newIndex: number | undefined, oldIndex: number | undefined) => {
        this.sendJsonMessage({
            user: getCookie('user'),
            action: 'changeVote',
            newChoice: newIndex,
            oldChoice: oldIndex,
        });
    }).bind(this);

    public changeVeto = ((newIndex: number | undefined, oldIndex: number | undefined) => {
        this.sendJsonMessage({
            user: getCookie('user'),
            action: 'changeVeto',
            newChoice: newIndex,
            oldChoice: oldIndex,
        });
    }).bind(this);

    public startVote = (() => {
        this.sendJsonMessage({
            user: getCookie('user'),
            action: 'endNominations',
        });
    }).bind(this);

    public endVote = (() => {
        this.sendJsonMessage({
            user: getCookie('user'),
            action: 'endVote',
        });
    }).bind(this);

    public changeRank = ((rank: number[]) => {
        this.sendJsonMessage({
            user: getCookie('user'),
            action: 'changeRank',
            rank: rank,
        });
    }).bind(this);

    public submitSelection = ((unSubmit: boolean = false) => {
        this.sendJsonMessage({
            user: getCookie('user'),
            action: 'submitSelection',
            unSubmit: unSubmit,
        });
    }).bind(this);

    public newRound = ((method: SelectionMethod) => {
        this.sendJsonMessage({
            user: getCookie('user'),
            action: 'newRound',
            method: method,
        });
    }).bind(this);
}