import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { ParticipantResult, SelectionMethod, isRankResult, isVoteResult } from "../../model/question";
import { QuestionClient } from "../../services/questionClient";

export function SubmitSelection({ participant, method, voteText }: {
    participant: ParticipantResult | undefined,
    method: SelectionMethod,
    voteText?: string,
}) {
    const { code } = useParams();
    const [disable, setDisable] = useState<boolean>(true);
    const didUnmount = useRef(false);
    const { questionClient } = QuestionClient.useWebSocket(code, undefined, didUnmount.current);

    useEffect(() => {
        return () => {
            didUnmount.current = true;
        };
    }, []);

    useEffect(() => {
        if (method === 'rank') {
            setDisable(false);
        } else if (participant && isVoteResult(participant) && (participant.votes?.length || participant.vetoes?.length)) {
            setDisable(false);
        } else if (participant && isRankResult(participant) && participant.rank.length) {
            setDisable(false);
        } else {
            setDisable(true);
        }
    }, [participant]);

    function submit(unSubmit: boolean) {
        questionClient.submitSelection(unSubmit);
    }

    return <div>
        <input type="button"
            value={participant?.submitted ? `Edit your ${voteText || 'Votes'}` : `Submit your ${voteText || 'Votes'}`}
            disabled={disable}
            onClick={() => submit(participant?.submitted || false)} />
    </div >
}