import { Place } from "../model/question";

export async function getSuggestedPlaces(code: string, primaryTypes?: string[], max?: number): Promise<Place[]> {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/places/search?code=${code}`
        + (primaryTypes ? `&primaryTypes=${primaryTypes}` : '')
        + (max ? `&max=${max}` : ''),
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });
    if (response.status === 200) {
        const responseObj = await response.json();
        return responseObj.places;
    } else {
        return [];
    }
}

export const restaurantCuisines = new Map<string, string>([
    ['american', 'american_restaurant'],
    ['bar', 'bar'],
    ['bbq', 'barbecue_restaurant'],
    ['brazilian', 'brazilian_restaurant'],
    ['burgers', 'hamburger_restaurant'],
    ['breakfast', 'breakfast_restaurant'],
    ['brunch', 'brunch_restaurant'],
    ['cafe', 'cafe'],
    ['chinese', 'chinese_restaurant'],
    ['coffee shop', 'coffee_shop'],
    ['fast food', 'fast_food_restaurant'],
    ['french', 'french_restaurant'],
    ['greek', 'greek_restaurant'],
    ['ice cream', 'ice_cream_shop'],
    ['indian', 'indian_restaurant'],
    ['indonesian', 'indonesian_restaurant'],
    ['italian', 'italian_restaurant'],
    ['japanese', 'japanese_restaurant'],
    ['korean', 'korean_restaurant'],
    ['lebanese', 'lebanese_restaurant'],
    ['mediterranean', 'mediterranean_restaurant'],
    ['mexican', 'mexican_restaurant'],
    ['middle eastern', 'middle_eastern_restaurant'],
    ['pizza', 'pizza_restaurant'],
    ['ramen', 'ramen_restaurant'],
    ['sandwiches', 'sandwich_shop'],
    ['seafood', 'seafood_restaurant'],
    ['spanish', 'spanish_restaurant'],
    ['steak', 'steak_house'],
    ['sushi', 'sushi_restaurant'],
    ['thai', 'thai_restaurant'],
    ['turkish', 'turkish_restaurant'],
    ['vegan', 'vegan_restaurant'],
    ['vegetarian', 'vegetarian_restaurant'],
    ['vietnamese', 'vietnamese_restaurant'],
]);

export const restaurantTypes = new Map<string, keyof Place>([
    ['curbside', 'hasCurbsidePickup'],
    ['delivery', 'hasDelivery'],
    ['dine in', 'hasDineIn'],
    ['reservable', 'isReservable'],
    ['takeout', 'hasTakeout'],
])