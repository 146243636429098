import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from './Feedback.module.scss';

export default function Feedback() {
    const { code } = useParams();
    const [rating, setRating] = useState<number>(0);
    const [message, setMessage] = useState<string>('');
    const [disabled, setDisabled] = useState<boolean>(true);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const starRefs = [
        useRef<HTMLImageElement>(null),
        useRef<HTMLImageElement>(null),
        useRef<HTMLImageElement>(null),
        useRef<HTMLImageElement>(null),
        useRef<HTMLImageElement>(null),
    ];
    const messageRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setDisabled(rating === 0 && message.trim().length === 0);
    }, [rating, message]);

    function starClick(num: number) {
        setRating(num);
        messageRef.current?.focus();
    }

    function onTextChanged() {
        const text = messageRef.current?.innerText;
        setMessage(text || '');
    }

    async function submitFeedback() {
        if (!disabled) {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/feedback`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                    body: JSON.stringify({ code: code, rating: rating, message: message }),
                });
            if (response.status !== 200) {
                throw new Error(response.statusText);
            }
            setSubmitted(true);
        }
    }

    function editSubmitted() {
        setSubmitted(false);
        setTimeout(() => {
            if (messageRef.current) {
                messageRef.current.innerText = message;
                messageRef.current.focus();
            }
        }, 0);
    }

    return <div className={styles.feedback}>
        {submitted ?
            <div className={styles.header}>
                Thank you for your feedback!&nbsp;
                <a href="javascript: void(0)" onClick={editSubmitted}>(Edit)</a>
            </div>
            : <>
                <div className={styles.header}>
                    What you do you think of Choosee.app?
                </div>
                <div>
                    {starRefs.map((ref, index) =>
                        <button key={index}
                            className={[styles.star, index < rating ? styles.selected : ''].join(' ')}
                            onClick={() => starClick(index + 1)}>
                            <img ref={ref} />
                        </button>
                    )}
                </div>
                <div>
                    <div ref={messageRef}
                        contentEditable={!submitted}
                        className={styles.feedbackText}
                        onInput={onTextChanged} />
                </div>
                <div>
                    <input type="button"
                        disabled={disabled}
                        value="Submit feedback"
                        onClick={submitFeedback} />
                </div>
            </>
        }
    </div >
}