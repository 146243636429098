import { useEffect, useRef, useState } from 'react';
import { Choice, Question, Selection, SelectionMethod, isHost } from '../../model/question';
import { getCookie } from '../../services/cookies';
import { QuestionClient } from '../../services/questionClient';
import Pill from '../common/pill/Pill';
import AiSuggestionPopup from './AiSuggestionPopup';
import EditChoicePopup from './EditChoicePopup';
import styles from './Nominate.module.scss';

export default function Nominate({ question }: {
    question: Question,
}) {
    const [user] = useState(getCookie('user'));
    const [host] = useState(isHost(question));
    const choiceText = useRef<HTMLInputElement>(null);
    const [editChoice, setEditChoice] = useState<Choice | undefined>(undefined);
    const didUnmount = useRef(false);
    const { questionClient } = QuestionClient.useWebSocket(question.code, undefined, didUnmount.current);
    const [selection, setSelection] = useState<Selection | undefined>(undefined);
    const [method, setMethod] = useState<SelectionMethod>('rank');
    const [suggestionLabel, setSuggestionLabel] = useState<string[]>(['a suggestion', 'suggestions', 'Suggestion']);
    const [aiSuggestionQuestion, setAiSuggestionQuestion] = useState<Question | undefined>(undefined);

    useEffect(() => {
        return () => {
            didUnmount.current = true;
        };
    }, []);

    useEffect(() => {
        setSelection(question.selections.find(sel => sel.active));
    }, [question]);

    useEffect(() => {
        setMethod(selection?.settings.method || 'rank');
    }, [selection]);

    useEffect(() => {
        setSuggestionLabel(method === 'quiz' ? ['an answer', 'answers', 'Answer'] : ['a suggestion', 'suggestions', 'Suggestion']);
    }, [method]);

    function addClicked() {
        if (choiceText.current) {
            const choice = choiceText.current.value;
            if (choice) {
                questionClient.addChoice(choice);
                choiceText.current.value = '';
                choiceText.current.focus();
            }
        }
    }

    function edit(choice: Choice) {
        setEditChoice(choice);
    }

    function correctChoiceChanged(e: React.ChangeEvent) {
        questionClient.markChoiceCorrect((e.target as HTMLInputElement).value);

    }

    function keyUp(e: React.KeyboardEvent) {
        if (e.key === 'Enter') {
            addClicked();
        }
    }

    function getFinePrint() {
        if (!host) {
            if ((selection?.choices?.length || 0) < 2) {
                switch (selection?.settings.method) {
                    case 'voting':
                    case 'rank': return 'The host can start the voting when there are at least 2 suggestions.';
                    case 'random': return 'The host can select a random winner when there are at least 2 suggestoins.';
                }
            } else {
                switch (selection?.settings.method) {
                    case 'voting':
                    case 'rank': return 'Waiting for the host to start voting';
                    case 'random': return 'Waiting for the host to select a random winner';
                }
            }
        }
    }

    return (
        <div className={styles.nominate}>
            <EditChoicePopup
                choice={editChoice}
                caption={`Edit ${suggestionLabel[2]}`}
                hide={() => setEditChoice(undefined)} />
            <AiSuggestionPopup
                question={aiSuggestionQuestion}
                addSuggestion={(suggestion: string) => questionClient.addChoice(suggestion)}
                hide={() => setAiSuggestionQuestion(undefined)} />
            <div className={styles.contentBox}>
                <div className={styles.contentHeader}>
                    <div>Add {suggestionLabel[0]}:</div>
                    <div className={styles.aiPrompt}>
                        {/* <img src="/icons/sparkle.svg" />
                        <span>
                            &nbsp;<a href="javascript: void(0)" onClick={() => setAiSuggestionQuestion(question)}>Need some fresh ideas?</a>
                        </span> */}
                    </div>
                </div>
                <div className={styles.addSuggestions}>
                    <input type="text"
                        ref={choiceText}
                        autoFocus
                        onFocus={e => e.target.select()}
                        onKeyUp={e => keyUp(e)}></input>
                    <input type="button"
                        className={styles.button}
                        value="Add"
                        onClick={addClicked} />
                </div>
            </div>
            {selection?.choices &&
                <div className={styles.contentBox}>
                    <div className={styles.contentHeader}>
                        Current {suggestionLabel[1]}:
                    </div>
                    {method === 'quiz' &&
                        ' (Check correct answer)'}
                    {!!selection?.choices.length &&
                        <div className={styles.contentBox}>
                            {selection?.choices.map((choice, index) =>
                                <Pill key={index}
                                    content={choice.text}
                                    clickHint={(host || choice.user === user) ? <>&#9998;</> : undefined}
                                    click={() => edit(choice)} />)}
                        </div>
                    }
                    {!selection?.choices.length &&
                        <div className={styles.noSuggestions}>(None yet)</div>}
                </div>}
        </div>
    );
}

