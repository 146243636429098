import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Title.module.scss';

export default function Title({ gatherFeedback }: { gatherFeedback?: () => any }) {
    // todo: make gatherFeedback work from home page
    const navigate = useNavigate();

    useEffect(() => {

        let timeout: number | undefined = undefined;

        const storeScroll = () => {
            document.documentElement.dataset.scroll = window.scrollY.toString();
        };

        window.addEventListener('scroll', () => {
            if (timeout) {
                window.cancelAnimationFrame(timeout);
            }
            timeout = window.requestAnimationFrame(() => {
                storeScroll();
                timeout = undefined;
            })
        });

        // initialize scroll position
        storeScroll();
    }, []);

    return <div className={styles.title}>
        <img src="/chooseeapp-logo.png" title="Choosee.app Logo" onClick={() => navigate('/')} />
        {/* <span className={styles.beta} onClick={() => gatherFeedback && gatherFeedback()}>
            beta
        </span> */}
    </div >
}