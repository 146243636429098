import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Choice, Place, QuestionSettings } from "../../model/question";
import { getSuggestedPlaces, restaurantCuisines, restaurantTypes } from "../../services/places";
import Popup from "../common/Popup";
import LocationSearchFilters from "../nominate/LocationSearchFilters";
import PlaceList from "../nominate/PlaceList";
import styles from './RestaurantSearchPopup.module.scss';

export default function RestaurantSearchPopup({ show, settings, choices, addPlace, hide }: {
    show: boolean,
    settings?: QuestionSettings,
    choices: Choice[] | undefined,
    addPlace: (place: Place) => void,
    hide: () => void,
}) {
    const { code } = useParams();
    const [serverFilter, setServerFilter] = useState<string>();
    const [clientFilters, setClientFilters] = useState<string[]>([]);
    const [searchText, setSearchText] = useState<string>('');
    const [suggestions, setSuggestions] = useState<Place[]>([]);
    const [filtered, setFiltered] = useState<Place[]>([]);
    const [active, setActive] = useState<Place>();

    const [libraries, setLibraries] = useState<{
        core: google.maps.CoreLibrary,
        maps: google.maps.MapsLibrary,
        marker: google.maps.MarkerLibrary,
    }>();
    const [map, setMap] = useState<google.maps.Map>();
    const markers = useRef<Map<string, google.maps.marker.AdvancedMarkerElement>>();
    const infoWindow = useRef<google.maps.InfoWindow>();

    useEffect(() => {
        initLibraries();
    }, []);

    useEffect(() => {
        if (code) {
            getSuggestedPlaces(code,
                [serverFilter]
                    .filter(tag => !!tag)
                    .map(tag => restaurantCuisines.get(tag as string) as string),
                20)
                .then(places => setSuggestions(places));
        } else {
            setSuggestions([]);
        }
    }, [code, serverFilter]);

    useEffect(() => {
        setFiltered(suggestions.filter(place =>
            clientFilters.length === 0 || clientFilters.find(filter => {
                const filterProp = restaurantTypes.get(filter);
                return !filterProp || !!place[filterProp];
            })));
    }, [suggestions, clientFilters]);

    useEffect(() => {
        if (libraries) {
            const mapDiv = document.getElementById('map') as HTMLDivElement;
            if (mapDiv && settings?.location?.location) {
                // console.log('creating map');
                const map = new libraries.maps.Map(mapDiv, {
                    zoom: 19,
                    center: settings.location?.location,
                    streetViewControl: false,
                    fullscreenControl: false,
                    mapId: 'suggestionsMap',
                });
                setMap(map);
                markers.current = undefined;
            }
        }
    }, [libraries, show]);

    useEffect(() => {
        if (libraries && map && filtered) {
            // console.log('updating markers');
            const bounds = new libraries.core.LatLngBounds();
            markers.current?.forEach(marker => marker.map = null);  // remove previous markers
            const newMarkers = new Map<string, google.maps.marker.AdvancedMarkerElement>();
            filtered.forEach(place => {
                if (place.location) {
                    const added = !!choices?.find(choice => choice.place?.id === place.id);
                    const marker = markers.current?.get(place.id) || new libraries.marker.AdvancedMarkerElement({
                        map: map,
                        position: place.location,
                        title: place.displayName,
                        zIndex: added ? 1 : 0,
                    });
                    marker.content = getPin(added)?.element;
                    marker.addListener('click', () => {
                        placeClicked(place);
                    });
                    marker.map = map;
                    newMarkers.set(place.id, marker);
                    bounds.extend(place.location);
                }
            });
            if (!markers.current) {
                map.fitBounds(bounds);
                // todo: listen to resize event to redo fitBounds?
            }
            if (newMarkers.size > 0) {
                markers.current = newMarkers;
            }
        }
    }, [libraries, map, filtered]);

    useEffect(() => {
        // todo: do search
    }, [searchText]);

    function getPin(added: boolean): google.maps.marker.PinElement | undefined {
        if (libraries) {
            return new libraries.marker.PinElement({
                borderColor: added ? styles.addedBorderColor : styles.borderColor,
                background: added ? styles.addedFillColor : styles.fillColor,
                glyphColor: added ? styles.addedGlyphColor : styles.glyphColor,
            });
        } else {
            return undefined;
        }
    }

    async function initLibraries() {
        const libs = await Promise.all([
            google.maps.importLibrary('core'),
            google.maps.importLibrary('maps'),
            google.maps.importLibrary('marker'),
        ]);
        setLibraries({
            core: libs[0] as google.maps.CoreLibrary,
            maps: libs[1] as google.maps.MapsLibrary,
            marker: libs[2] as google.maps.MarkerLibrary,
        })
    }

    function placeButtonClicked(place: Place) {
        addPlace(place);
        if (markers.current) {
            const marker = markers.current.get(place.id);
            if (marker) {
                marker.content = getPin(true)?.element;
                marker.zIndex = 1;
                placeClicked(place);
            }
        }
    }

    function placeClicked(place: Place) {
        setActive(place);
        if (markers.current) {
            const marker = markers.current.get(place.id);
            if (libraries && marker) {
                if (!infoWindow.current) {
                    infoWindow.current = new libraries.maps.InfoWindow();
                }
                infoWindow.current.close();
                infoWindow.current.setContent(place.displayName);
                infoWindow.current.open({
                    anchor: marker,
                    map: map,
                })
            }
        }
    }

    function updateFilter(selected: Map<string, string>) {
        setServerFilter(selected.get('Cuisine'));
        const types = selected.get('Services');
        setClientFilters(types ? [types] : []);
    }

    return show
        ? <Popup caption={"Find Restaurants"}
            hide={hide}>
            <div className={styles.body}>
                <div className={styles.filters}>
                    <LocationSearchFilters
                        tags={new Map([
                            ['Cuisine', Array.from(restaurantCuisines.keys())],
                            ['Services', Array.from(restaurantTypes.keys())],
                        ])}
                        updateSelected={updateFilter}
                        updateSearchText={setSearchText} />
                </div>
                <div className={styles.container}>
                    <div className={styles.mapContainer}>
                        <div id="map" className={styles.map}></div>
                    </div>
                    <div className={styles.listContainer}>
                        {filtered.length === 0 &&
                            <div className={styles.noMatches}>
                                <div>No restaurants matching your filters.</div>
                            </div>}
                        <PlaceList places={filtered}
                            choices={choices}
                            active={active}
                            placeButtonClicked={placeButtonClicked}
                            placeClicked={placeClicked} />
                    </div>
                </div>
            </div>
        </Popup>
        : null;
}