import { BrowserRouter, Route, Routes } from 'react-router-dom';
import CollaborateWrapper from '../collaborate/CollaborateWrapper';
import Restaurant from '../restaurant/Restaurant';
import styles from './App.module.scss';
import Home from './Home';

function App() {
  return (
    <div className={styles.app}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/q/:code" element={<CollaborateWrapper />} />
          <Route path="/restaurant" element={<Restaurant />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
