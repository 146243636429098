import { DndContext, DragEndEvent, DragOverlay, DragStartEvent, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, arrayMove, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useEffect, useRef, useState } from 'react';
import { Choice, ParticipantResult, Question, RankResult, Selection, getActiveSelection, randomizeChoices } from '../../model/question';
import { getCookie } from '../../services/cookies';
import { QuestionClient } from '../../services/questionClient';
import { CustomKeyboardSensor, CustomPointerSensor, CustomTouchSensor } from './DndSensors';
import styles from './Rank.module.scss';
import { SortableItem } from './SortableItem';
import { SubmitSelection } from './SubmitSelection';

export default function Rank({ question }: {
    question: Question
}) {
    const [user] = useState(getCookie('user'));
    const [participant, setParticipant] = useState<ParticipantResult | undefined>(undefined);
    const [selection, setSelection] = useState<Selection | undefined>(undefined);
    const [sortedChoices, setSortedChoices] = useState<Choice[]>([]);
    const [rankItems, setRankItems] = useState<Choice[]>([]);
    const [activeChoice, setActiveChoice] = useState<Choice | undefined>(undefined);
    const didUnmount = useRef(false);
    const { questionClient } = QuestionClient.useWebSocket(question.code, undefined, didUnmount.current);

    const sensors = useSensors(
        useSensor(CustomTouchSensor),
        useSensor(CustomPointerSensor),
        useSensor(CustomKeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    useEffect(() => {
        return () => {
            didUnmount.current = true;
        };
    }, []);

    useEffect(() => {
        setSelection(getActiveSelection(question));
    }, [question]);

    useEffect(() => {
        if (selection?.choices) {
            sortChoices(selection.choices);
        } else {
            setSortedChoices([]);
        }

    }, [selection]);

    useEffect(() => {
        setParticipant(selection?.participants?.find(participant => participant.user === user));
    }, [selection, user]);

    useEffect(() => {
        if (selection && participant) {
            const result = participant as RankResult | undefined;
            if (result && result.rank.length === selection.choices.length) {
                if (result.rank.length !== rankItems.length) {
                    setRankItems(result.rank.map(index => selection.choices[index]));
                } // else the rank items are already set
                return;
            }
        }
        setRankItems([...sortedChoices]);
    }, [sortedChoices, participant]);

    useEffect(() => {
        // todo: this seems to still result in changeRank being called too early
        if (selection && selection.choices.length > 0) {
            const newRank = rankItems.map(item => selection.choices.findIndex(choice => choice.text === item.text));
            if (!participant) {
                questionClient.changeRank(newRank);
            } else {
                const oldRank = (participant as RankResult).rank;
                if (oldRank.length !== newRank.length || !oldRank.every((num, index) => newRank[index] === num)) {
                    // only call this if the rank has actually changed
                    questionClient.changeRank(newRank);
                }
            }
        }
    }, [selection, participant, rankItems]);

    async function sortChoices(choices: Choice[]) {
        setSortedChoices(await randomizeChoices(choices, user || '', question.code));
    }

    function handleDragStart(event: DragStartEvent) {
        const active = rankItems.find(item => item.text === event.active.id);
        setActiveChoice(active);
    }

    function handleDragEnd(event: DragEndEvent) {
        setActiveChoice(undefined);
        const { active, over } = event;

        if (over && active.id !== over.id) {
            setRankItems((items) => {
                const oldIndex = items.findIndex(item => item.text === active.id);
                const newIndex = items.findIndex(item => item.text === over.id);
                const newRank = arrayMove(items, oldIndex, newIndex);
                return newRank;
            });
        }
        setTimeout(() => {
            const choice = rankItems.find(item => item.text === active.id);
            if (choice) {
                setFocus(choice);
            }
        }, 0);
    }

    function moveUp(choice: Choice) {
        setRankItems(items => {
            const index = items.findIndex(item => item.text === choice.text);
            if (index > 0) {
                const newRank = arrayMove(items, index, index - 1);
                return newRank;
            } else {
                return items;
            }
        });
        setTimeout(() => setFocus(choice, 0), 0);
    }

    function moveDown(choice: Choice) {
        setRankItems(items => {
            const index = items.findIndex(item => item.text === choice.text);
            if (index < items.length - 1) {
                const newRank = arrayMove(items, index, index + 1);
                return newRank;
            } else {
                return items;
            }
        });
        setTimeout(() => setFocus(choice, 1), 0);
    }

    function setFocus(choice: Choice, button?: number) {
        let elmtId = '';
        switch (button) {
            case 0: elmtId = `btnUp_${choice.text}`; break;
            case 1: elmtId = `btnDown_${choice.text}`; break;
            default: elmtId = `option_${choice.text}`; break;
        }
        if (elmtId) {
            let elmt = document.getElementById(elmtId) as HTMLButtonElement | undefined;
            if (elmt && !elmt.disabled) {
                elmt.focus();
            } else {
                document.getElementById(`option_${choice.text}`)?.focus();
            }
        }
    }

    return (
        <div className={styles.vote}>
            {selection?.choices && <>
                <div className={styles.caption}>
                    {!(participant?.submitted) && <>Order your choices from your most preferred to least preferred.</>}
                    {participant?.submitted && <>Your vote has been recorded.</>}
                </div>
                <SubmitSelection
                    participant={participant}
                    method={selection.settings.method} />
                <div id={styles.options}>
                    <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        modifiers={[restrictToVerticalAxis]}
                        onDragStart={handleDragStart}
                        onDragEnd={handleDragEnd}>
                        <SortableContext
                            items={rankItems.map(choice => choice.text)}
                            strategy={verticalListSortingStrategy}>
                            {rankItems.map((choice, index) => {
                                return <SortableItem
                                    key={index}
                                    choice={choice}
                                    index={index}
                                    numChoices={rankItems.length}
                                    participant={participant}
                                    activeChoice={activeChoice}
                                    disabled={participant?.submitted || false}
                                    moveUp={moveUp}
                                    moveDown={moveDown} />
                            })}
                        </SortableContext>
                        <DragOverlay>
                            {activeChoice &&
                                <SortableItem
                                    isActive={true}
                                    choice={activeChoice} />}
                        </DragOverlay>
                    </DndContext>
                </div>
            </>}
        </div >
    );
}

