import { useState } from 'react';
import Title from '../common/Title';
import styles from './Home.module.scss';
import HomeLayout from './HomeLayout';
import HowItWorks from './HowItWorks';
import MainCard from './MainCard';
import UserActivity from './UserActivity';

export default function Home() {
  const [activityCardVisible, setActivityCardVisible] = useState<boolean>(false);

  return (
    <div className={styles.home}>
      <Title />
      <div className={styles.body}>
        <HomeLayout
          left={<MainCard />}
          right={<UserActivity onOpen={() => setActivityCardVisible(true)} />}
          bottom={<HowItWorks />}
          rightVisible={activityCardVisible}
        />
      </div>
    </div >
  );
}