import { useEffect, useState } from 'react';
import { Question, Selection, SelectionMethod, VoteSettings } from "../../model/question";
import { SelectionMethodToggle } from './SelectionMethodToggle';
import styles from './Settings.module.scss';

export function Settings({ question, settingsChanged }: {
    question: Question,
    settingsChanged: () => any;
}) {
    const [selection, setSelection] = useState<Selection | undefined>(undefined);
    const [settings, setSettings] = useState<VoteSettings | undefined>(undefined);
    const [numVotes, setNumVotes] = useState<number | ''>('');
    const [numVetoes, setNumVetoes] = useState<number | ''>('');
    const [liveResults, setLiveResults] = useState<boolean>(false);

    useEffect(() => {
        setSelection(question.selections.find(sel => sel.active));
    }, [question]);

    useEffect(() => {
        setSettings(selection?.settings);
    }, [selection]);

    useEffect(() => {
        setNumVotes(((selection?.settings) as VoteSettings)?.numVotes || 1);
        setNumVetoes(((selection?.settings) as VoteSettings)?.numVetoes || 0);
        setLiveResults(((selection?.settings) as VoteSettings)?.liveResults || false);
    }, [settings]);

    const onMethodChanged = function (method: SelectionMethod) {
        if (selection) {
            selection.settings.method = method;
            settingsChanged();
        }
    }

    const onNumVotesChanged = function (value: string) {
        const parsed = parseInt(value);
        const num = Number.isNaN(parsed) ? '' : parsed;
        setNumVotes(num);
        if (num !== '' && selection) {
            const settings = (selection.settings || {}) as VoteSettings;
            settings.numVotes = num;
            selection.settings = settings;
            settingsChanged();
        }
    }

    const onNumVetoesChanged = function (value: string) {
        const parsed = parseInt(value);
        const num = Number.isNaN(parsed) ? '' : parsed;
        setNumVetoes(num);
        if (num !== '' && selection) {
            const settings = (selection.settings || {}) as VoteSettings;
            settings.numVetoes = num;
            selection.settings = settings;
            settingsChanged();
        }
    }

    // const onLiveResultsChanged = function (checked: boolean) {
    //     if (selection) {
    //         const settings = (selection.settings || {}) as VoteSettings;
    //         settings.liveResults = checked;
    //         selection.settings = settings;
    //         settingsChanged();
    //     }
    // }

    return question.state === 'nominating' ?
        <div className={styles.settings}>
            {settings && <div className={styles.contentBox}>
                <div className={styles.contentHeader}>Selection method:</div>
                <div className={styles.fieldBox}>
                    <div className={[styles.fieldWrapper, styles.fullRow].join(' ')}>
                        <SelectionMethodToggle method={settings.method}
                            onMethodChanged={onMethodChanged} />
                    </div>
                </div>
            </div>
            }
            {settings?.method === 'voting' && <div className={styles.contentBox}>
                <div className={styles.contentHeader}>Voting settings:</div>
                <div className={styles.fieldBox}>
                    <div className={styles.fieldWrapper}>
                        <span><img className={styles.icon}
                            src="/icons/thumb-up.svg"
                            title="Vote" /> / person:</span>
                        <input type="text"
                            value={numVotes}
                            onFocus={e => e.target.select()}
                            onChange={e => onNumVotesChanged(e.target.value)}
                        ></input>
                    </div>
                    <div className={styles.fieldWrapper}>
                        <span><img className={styles.icon}
                            src="/icons/thumb-down.svg"
                            title="Veto" /> / person:</span>
                        <input type="text"
                            value={numVetoes}
                            onFocus={e => e.target.select()}
                            onChange={e => onNumVetoesChanged(e.target.value)}
                        ></input>
                    </div>
                    {/* <div className={styles.fieldWrapper}>
                        <span>Show live results:</span>
                        <input type="checkbox"
                            checked={liveResults}
                            onChange={e => onLiveResultsChanged(e.target.checked)} />
                    </div> */}
                </div>
                <div className={styles.finePrint}>
                    Winner will be the option with the most
                    <span> <img className={styles.icon} src="/icons/thumb-up.svg" />s</span>
                    {!!numVetoes
                        ? <> out of those with the fewest <span> <img className={styles.icon} src="/icons/thumb-down.svg" />s.</span></>
                        : <>.</>}
                </div>
            </div >}
        </div> : null
}