import { FocusEvent, useEffect, useRef, useState } from 'react';
import SearchField from '../common/SearchField';
import SelectablePill from '../common/pill/SelectablePill';
import styles from './LocationSearchFilters.module.scss';

export default function LocationSearchFilters({ tags, updateSelected, updateSearchText }: {
    tags: Map<string, string[]>,
    updateSelected: (selected: Map<string, string>) => void,
    updateSearchText: (text: string) => void;
}) {
    const [selectedFilter, setSelectedFilter] = useState<string>();
    const [showTags, setShowTags] = useState(false);
    const [selected, setSelected] = useState<Map<string, string>>(new Map<string, string>());
    const tagsRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!showTags) {
            setTimeout(() => setSelectedFilter(undefined), 300);
        }
    }, [showTags]);

    function selectFilter(filterName: string) {
        if (selectedFilter === filterName) {
            setSelectedFilter(undefined);
            setShowTags(false);
        } else {
            setSelectedFilter(filterName);
            setShowTags(true);
            tagsRef.current?.focus();
        }
    }

    function removeSelected(key: string) {
        const newMap = new Map(selected);
        newMap.delete(key);
        setSelected(newMap);
        updateSelected(newMap);
    }

    function addSelected(key: string, tag: string) {
        const newMap = new Map(selected);
        newMap.set(key, tag);
        setSelected(newMap);
        updateSelected(newMap);
    }

    function onTagsBlur(e: FocusEvent<HTMLDivElement, Element>) {
        if (!e.currentTarget.contains(e.relatedTarget)) {
            // focus is leaving
            setShowTags(false);
        }
    }

    return <div className={styles.filter}>
        <div className={styles.mainRow}>
            <span className={[styles.icon, selected.size > 0 ? styles.filterApplied : ''].join(' ')}></span>
            {Array.from(tags.keys()).map(filter => {
                const selectedTag = selected.get(filter);
                return <button
                    className={[
                        styles.pill,
                        showTags && selectedFilter === filter ? styles.selected : '',
                        !!selectedTag ? styles.filtered : '',
                    ].join(' ')}
                    onClick={() => selectFilter(filter)}>
                    <span>{selectedTag ? selectedTag : filter}</span>
                    <span className={styles.arrow}></span>
                </button>
            })}
            <span className={[styles.pill].join(' ')}>
                <SearchField textUpdated={updateSearchText} />
            </span>
        </div>
        <div ref={tagsRef} className={styles.tags}
            tabIndex={0}
            onBlur={onTagsBlur}>
            {showTags && selectedFilter && tags.get(selectedFilter)?.map(tag => {
                const selectedTag = selected.get(selectedFilter) || '';
                return <SelectablePill key={tag}
                    content={<span className={styles.tag}>{tag}</span>}
                    selected={tag === selectedTag}
                    onSelectionChanged={select => {
                        if (selectedFilter) {
                            if (select) {
                                addSelected(selectedFilter, tag);
                            } else {
                                removeSelected(selectedFilter);
                            }
                        }
                    }} />
            })}
        </div>
    </div>
}