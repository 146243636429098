import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { ReadyState } from "react-use-websocket";
import { Question, QuestionState, Selection, SelectionMethod, getActiveSelection, isHost } from '../../model/question';
import { Notifications } from "../../services/notifications";
import { QuestionClient } from "../../services/questionClient";
import LocationNominate from "../nominate/LocationNominate";
import Nominate from "../nominate/Nominate";
import Results from "../results/Results";
import Quiz from "../selection/Quiz";
import Rank from "../selection/Rank";
import Vote from "../selection/Vote";
import CollaborateFooter from "./CollaborateFooter";
import CollaborateHeader from "./CollaborateHeader";
import CollaborateLayout from "./CollaborateLayout";
import QuestionCard from "./QuestionCard";

export default function Collaborate({ initialQuestion, error, setError, gatherFeedback }: {
    initialQuestion: Question,
    error: string | undefined,
    setError: (error: string | undefined, recoverable?: boolean) => void,
    gatherFeedback: () => void,
}) {

    const { code } = useParams();
    const [question, setQuestion] = useState<Question>(initialQuestion);
    const [selection, setSelection] = useState<Selection | undefined>(undefined);
    const [method, setMethod] = useState<SelectionMethod>('rank');
    const [suggestionLabel, setSuggestionLabel] = useState<string[]>(['a suggestion', 'suggestions']);
    const [state, setState] = useState<QuestionState | undefined>(undefined);
    const previousState = useRef<QuestionState | undefined>(undefined);
    const didUnmount = useRef(false);
    const { questionClient, lastJsonMessage, readyState } = QuestionClient.useWebSocket(code, error, didUnmount.current);

    useEffect(() => {
        if (lastJsonMessage) {
            //console.log(`message: ${JSON.stringify(lastJsonMessage)}`);
            if (lastJsonMessage.error) {
                setError(lastJsonMessage.error, true);
            } else if (lastJsonMessage.question) {
                setQuestion(lastJsonMessage.question);
                setError(undefined);
            }
        }
    }, [lastJsonMessage]);

    useEffect(() => {
        setSelection(question ? getActiveSelection(question) : undefined);
    }, [question]);

    useEffect(() => {
        console.log(`connection: ${ReadyState[readyState]}`);
    }, [readyState]);

    useEffect(() => {
        if (readyState === ReadyState.CLOSED && question) {
            window.location.reload();
        }
    }, [readyState, question]);

    useEffect(() => {
        setState(question?.state ?? 'nominating');
    }, [question]);

    useEffect(() => {
        setMethod(selection?.settings.method || 'rank');
    }, [selection]);

    useEffect(() => {
        setSuggestionLabel(method === 'quiz' ? ['an answer', 'answers'] : ['a suggestion', 'suggestions']);
    }, [method]);

    useEffect(() => {
        if (!isHost(question)
            && Notifications.isEnabled
            && previousState.current !== undefined
            && previousState.current !== state) {
            console.log('notifying');
            if (state === 'selecting') {
                navigator.serviceWorker.ready.then((registration) => {
                    registration.showNotification(`We're ready for your vote!`, {
                        body: `Submit your votes for the question: ${question.text}\r\nClick to vote!`,
                        icon: '/favicon-color.png',
                        data: question.code,
                    });
                });
            } else if (state === 'results') {
                navigator.serviceWorker.ready.then((registration) => {
                    registration.showNotification('The results are in!', {
                        body: `Results are ready for the question: ${question.text}\r\nClick to view!`,
                        icon: '/favicon-color.png',
                        data: question.code,
                    });
                });
            }
        }
        previousState.current = state;
    }, [state]);

    function settingsChanged() {
        if (selection) {
            questionClient.settingsChanged(selection.settings.method || '', selection.settings || {});
        }
    }

    function getStateMessage() {
        switch (state) {
            case 'nominating': return `Accepting ${suggestionLabel[1]}`;
            case 'selecting': return method === 'quiz' ? 'Quiz' : 'Voting';
            case 'results': return 'Here are your results!';
            default: return '';
        }
    }

    return <CollaborateLayout
        top={
            <CollaborateHeader question={question} />
        }
        left={question.state !== 'results' ?
            <QuestionCard question={question}
                stateMessage={getStateMessage()}
                suggestionLabel={suggestionLabel}
                settingsChanged={settingsChanged} />
            : undefined
        }
        right={
            <>
                {state === 'nominating' && question.settings?.type === 'restaurant' && <LocationNominate question={question} />}
                {state === 'nominating' && question.settings?.type !== 'restaurant' && <Nominate question={question} />}
                {state === 'selecting' && selection?.settings.method === 'voting' && <Vote question={question} />}
                {state === 'selecting' && selection?.settings.method === 'rank' && <Rank question={question} />}
                {state === 'selecting' && selection?.settings.method === 'quiz' && <Quiz question={question} />}
                {state === 'results' && <Results question={question} />}
            </>
        }
        bottom={
            <CollaborateFooter question={question}
                suggestionLabel={suggestionLabel}
                gatherFeedback={gatherFeedback} />
        } />
}

