import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Participant, Question, QuestionState, Selection, SelectionMethod, getActiveSelection, isHost } from '../../model/question';
import { getCookie } from "../../services/cookies";
import { QuestionClient } from "../../services/questionClient";
import TextWithTooltip from "../common/TextWithTooltip";
import styles from './CollaborateFooter.module.scss';

export default function CollaborateFooter({ question, suggestionLabel, gatherFeedback }: {
    question: Question,
    suggestionLabel: string[],
    gatherFeedback: () => void,
}) {

    const { code } = useParams();
    const [user, _] = useState(getCookie('user'));
    const [selection, setSelection] = useState<Selection | undefined>(undefined);
    const [method, setMethod] = useState<SelectionMethod>('rank');
    const [submittedParticipants, setSubmittedParticipants] = useState<string[]>([]);
    const [state, setState] = useState<QuestionState | undefined>(undefined);
    const [disableNextButton, setDisableNextButton] = useState<boolean>(true);
    const { questionClient } = QuestionClient.useWebSocket(code);
    const navigate = useNavigate();

    useEffect(() => {
        setSelection(getActiveSelection(question));
    }, [question]);

    useEffect(() => {
        setState(question.state ?? 'nominating');
    }, [question]);

    useEffect(() => {
        setMethod(selection?.settings.method || 'rank');
        switch (state) {
            case 'nominating':
                setSubmittedParticipants(Array.from(new Set(selection?.choices.map(choice => choice.user))));
                break;
            case 'selecting':
                setSubmittedParticipants(selection?.participants.filter(result => result.submitted).map(result => result.user) || []);
                break;
        }
    }, [selection, state]);

    useEffect(() => {
        if ((selection?.choices?.length || 0) < 2) {
            setDisableNextButton(true);
        } else if (method === 'quiz' && !selection?.choices.find(choice => choice.correct)) {
            setDisableNextButton(true);
        } else {
            setDisableNextButton(false);
        }
    }, [selection, method]);

    function getSubmittedNames(): string {
        const submitted = submittedParticipants
            .map(user => question.participants.find(part => part.id === user))
            .filter((part): part is Participant => !!part)
            .map(part => part.name) || [];
        const names = submitted.filter((name): name is string => !!name);
        const unnamed = submitted.length - names.length;
        return (unnamed > 0 ? [...names, `${unnamed} unnamed`] : names).join(', ');
    }

    async function askSameQuestion() {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/questions/${code}/copy`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });
        if (response.status !== 200) {
            throw new Error(response.statusText);
        }
        const respObj = await response.json();
        if (respObj.error) {
            throw new Error(respObj.error);
        }
        const question = respObj.question as Question;
        navigate(`/q/${question.code}`);
    }

    function askNewQuestion() {
        navigate('/');
    }

    function getNominatingButtonText(): string {
        switch (selection?.settings.method || 'voting') {
            case 'voting':
            case 'rank': return `Stop ${suggestionLabel[1]} and Vote`;
            case 'quiz': return 'Start quiz';
            case 'random': return 'Get Winner!';
            default: return 'Continue';
        }
    }

    function endVote() {
        if ((user && submittedParticipants.indexOf(user) > -1)
            || confirm(`You haven't submitted your vote yet. Are you sure you want to end voting?`)) {
            questionClient.endVote();
        }
    }

    function getNextStepButton() {
        if (question) {
            switch (state) {
                case 'nominating':
                    return isHost(question) && <div className={styles.nextStep}>

                        <div>
                            <TextWithTooltip text={submittedParticipants.length.toString()} tooltip={getSubmittedNames} />
                            &nbsp;participant{submittedParticipants.length === 1 ? ' has' : 's have'} submitted {suggestionLabel[1]}.
                        </div>
                        <input type="button"
                            disabled={disableNextButton}
                            value={getNominatingButtonText()}
                            onClick={questionClient.startVote} />
                        {disableNextButton && (
                            (method !== 'quiz' && <div className={styles.finePrint}>Add at least 2 {suggestionLabel[1]} to continue.</div>) ||
                            (method === 'quiz' && <div className={styles.finePrint}>Add at least 2 {suggestionLabel[1]} and mark 1 as correct to continue.</div>))}
                    </div>
                case 'selecting':
                    return isHost(question) && <div className={styles.nextStep}>
                        <div>
                            <TextWithTooltip text={submittedParticipants.length.toString()} tooltip={getSubmittedNames} />
                            &nbsp;participant{submittedParticipants.length === 1 ? ' has' : 's have'} submitted their vote.
                        </div>
                        <input type="button"
                            value="End voting"
                            disabled={submittedParticipants.length === 0}
                            onClick={endVote} />
                    </div>
                case 'results':
                    return <div className={styles.nextStep}>
                        <div className={styles.buttonRow}>
                            <button className={styles.button}
                                onClick={askSameQuestion}>
                                <span>Repeat this question</span>
                                <img className={styles.repeat} />
                            </button>
                            <button className={styles.button}
                                onClick={askNewQuestion}>
                                <span>Ask a different question</span>
                                <img className={styles.new} />
                            </button>
                        </div>
                    </div>
            }
        }
    }

    function getFinePrint() {
        switch (question.state) {
            case 'nominating':
                if ((selection?.choices?.length || 0) < 2) {
                    switch (selection?.settings.method) {
                        case 'voting':
                        case 'rank': return 'The host can start the voting when there are at least 2 suggestions.';
                        case 'random': return 'The host can select a random winner when there are at least 2 suggestoins.';
                    }
                } else {
                    switch (selection?.settings.method) {
                        case 'voting':
                        case 'rank': return 'Waiting for the host to start voting';
                        case 'random': return 'Waiting for the host to select a random winner';
                    }
                }
                break;
            case 'selecting':
                return 'Waiting for the host to end voting';
        }
    }

    return <div className={styles.card} >
        {getNextStepButton()}
        {
            !isHost(question) &&
            <>
                <div className={styles.finePrint}>
                    {getFinePrint()}
                </div>
            </>
        }
    </div >
}

