import { useEffect, useState } from "react";
import { Question, isHost } from "../../model/question";
import NotifyMe from "../common/NotifyMe";
import styles from './CollaborateHeader.module.scss';

export default function ({ question }: {
    question: Question
}) {
    const [step, setStep] = useState<number>(0);

    useEffect(() => {
        switch (question.state) {
            case 'nominating': setStep(1); break;
            case 'selecting': setStep(2); break;
            case 'results': setStep(3); break;
        }
    }, [question]);

    return <div className={styles.body}>
        <div className={styles.header}>
            {question.text}
        </div>
        <div className={styles.stepper}>
            <div className={[styles.step, step === 1 && styles.active].join(' ')}>
                <div className={styles.number}>1</div>
                <div className={styles.text}>Suggest</div>
            </div>
            <div className={styles.line}></div>
            <div className={[styles.step, step === 2 && styles.active].join(' ')}>
                <div className={styles.number}>2</div>
                <div className={styles.text}>Vote</div>
            </div>
            <div className={styles.line}></div>
            <div className={[styles.step, step === 3 && styles.active].join(' ')}>
                <div className={styles.number}>3</div>
                <div className={styles.text}>Results</div>
            </div>
        </div>
        {!isHost(question) &&
            <div>
                <NotifyMe question={question} />
            </div>}
    </div>
}