import { SelectionMethod } from '../../model/question';
import ToggleButtons from '../common/ToggleButtons';
import styles from './TieBreaker.module.scss';

export default function TieBreaker({ method, setMethod, start }: {
    method: SelectionMethod,
    setMethod: (method: SelectionMethod) => any,
    start: () => any,
}) {
    return <div className={styles.tieBreaker}>
        <span>Tie-Breaker:</span>
        <ToggleButtons
            options={[
                { key: 'voting', label: 'Voting' },
                { key: 'random', label: 'Random' },
            ]}
            active={method}
            setActive={val => setMethod(val as SelectionMethod)}
            size={'small'}
        />
        <input type="button" className={[styles.tieButton, styles.smallButton].join(' ')}
            value="Start"
            onClick={start} />
    </div>
}