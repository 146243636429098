import styles from './Pill.module.scss';

export default function Pill({ content, clickHint, click, className }: {
    content: string | JSX.Element,
    clickHint?: string | JSX.Element,
    click?: () => void,
    className?: string,
}) {
    return <span className={[styles.pill, click ? styles.clickable : '', className || ''].join(' ')}
        onClick={() => click && click()}>
        <span className={styles.content}>{content}</span>
        {clickHint && click &&
            <span className={styles.clickHint}>
                {clickHint}
            </span>}
    </span>
}