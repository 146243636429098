import { KeyboardEvent, useEffect, useRef, useState } from "react";
import styles from './SearchField.module.scss';

export default function SearchField({ textUpdated }: {
    textUpdated: (text: string) => void,
}) {
    const [searchTextActive, setSearchTextActive] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [lastSearchText, setLastSearchText] = useState('');
    const textRef = useRef<HTMLSpanElement>(null);
    const searchRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        if (searchTextActive) {
            textRef.current?.focus();
        }
    }, [searchTextActive]);

    function selectText() {
        if (textRef.current) {
            var range = document.createRange();
            range.selectNodeContents(textRef.current);
            var sel = window.getSelection();
            sel?.removeAllRanges();
            sel?.addRange(range);
        }
    }

    function onTextInput() {
        const text = textRef.current?.innerText.trim();
        setSearchText(text || '');
    }

    function onTextKeyDown(e: KeyboardEvent<HTMLSpanElement>) {
        if (e.key === 'Enter') {
            doSearch();
            //selectText(); // todo: why does this remove all the text?
        }
    }

    function onTextBlur() {
        if (searchText === '') {
            setSearchTextActive(false);
        } else {
            var sel = window.getSelection();
            sel?.removeAllRanges();
        }
        doSearch();
    }

    function searchClicked() {
        if (!searchTextActive) {
            setSearchTextActive(true);
        } else {
            doSearch();
        }
    }

    function doSearch() {
        if (searchText !== lastSearchText) {
            setLastSearchText(searchText);
            textUpdated(searchText);
        }
    }

    return <>
        <span className={styles.textWrapper}>
            <span ref={textRef}
                className={styles.searchText}
                tabIndex={searchTextActive ? 0 : -1}
                contentEditable={true}
                spellCheck={false}
                onFocus={selectText}
                onKeyDown={onTextKeyDown}
                onInput={onTextInput}
                onBlur={onTextBlur}></span>
        </span>
        <button ref={searchRef}
            className={[styles.search].join(' ')}
            tabIndex={0}
            onClick={searchClicked}></button>
    </>

}