import { useEffect, useRef, useState } from 'react';
import styles from './FeedbackPopup.module.scss';
import Popup from "./Popup";

export default function FeedbackPopup({ code, hide }: {
    code?: string,
    hide: () => void,
    children?: never[],
}) {
    const [message, setMessage] = useState<string>('');
    const [submitted, setSubmitted] = useState<boolean>(false);
    const didUnmount = useRef(false);

    useEffect(() => {
        return () => {
            didUnmount.current = true;
        };
    }, []);

    useEffect(() => {
        if (code) {
            setSubmitted(false);
            document.getElementById('feedbackText')?.focus();
        }
    }, [code]);

    function onTextChanged() {
        const text = document.getElementById('feedbackText')?.innerText;
        setMessage(text || '');
    }

    async function submitFeedback() {
        if (message) {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/feedback`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                    body: JSON.stringify({ code: code, message: message }),
                });
            if (response.status !== 200) {
                throw new Error(response.statusText);
            }
            setSubmitted(true);
            setTimeout(() => hide(), 1500);
        }

    }

    return code
        ? <Popup
            hide={hide}
            caption={'Got Feedback?'}>
            <div className={styles.scrollDiv}>
                <div>We'd love your comments and suggestions!</div>
                <div contentEditable={!submitted}
                    id="feedbackText"
                    className={styles.feedbackText}
                    onInput={onTextChanged} />
                {submitted
                    ? <>
                        <div>Thanks for your feedback!</div>
                        <div className={styles.underline}></div>
                    </>
                    : <input type="button"
                        value="Submit Feedback"
                        disabled={!message}
                        onClick={submitFeedback} />}
            </div>
        </Popup >
        : null;
}