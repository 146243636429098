import { useEffect, useRef, useState } from 'react';
import { Question, Selection, SelectionMethod, getPlace, getVetoes, getVotes, isHost } from '../../model/question';
import { QuestionClient } from '../../services/questionClient';
import AllResultsList from './AllResultsList';
import Feedback from './Feedback';
import { RankTotals } from './RankTotals';
import styles from './Results.module.scss';
import Spinner from './Spinner';
import { ThumbUpDownTotals } from './ThumbUpDownTotals';
import TieBreaker from './TieBreaker';

export default function Results({ question }: {
    question: Question,
}) {
    const [selection, setSelection] = useState<Selection>();
    const [tieBreakerMethod, setTieBreakerMethod] = useState<SelectionMethod>('voting');
    const didUnmount = useRef(false);
    const { questionClient } = QuestionClient.useWebSocket(question.code, undefined, didUnmount.current);

    useEffect(() => {
        return () => {
            didUnmount.current = true;
        };
    }, []);

    useEffect(() => {
        setSelection(question.selections.find(sel => sel.active));
    }, [question]);

    function startTieBreaker() {
        questionClient.newRound(tieBreakerMethod);
    }

    return (
        <div className={styles.results}>
            <div className={styles.card}>
                {selection?.choices && <>
                    <div className={styles.contentBox}>
                        <div className={styles.contentHeader}>
                            {selection.result?.winners?.length === 1 ? 'And the winner is...' : 'It\'s a tie!'}
                        </div>
                        {selection.settings.method !== 'random'
                            && <div className={styles.winner}>
                                {selection.result?.winners?.map(winner =>
                                    <div key={winner} className={styles.option}>
                                        <span>{selection.choices?.[winner].text}</span>
                                        {selection?.settings.method === 'voting' &&
                                            <ThumbUpDownTotals
                                                up={getVotes(selection, winner)}
                                                down={getVetoes(selection, winner)}
                                                centered={true} />}
                                        {selection?.settings.method === 'rank' &&
                                            < RankTotals
                                                first={getPlace(selection, winner, 0)}
                                                second={getPlace(selection, winner, 1)}
                                                third={getPlace(selection, winner, 2)}
                                                centered={true} />}
                                    </div>)}
                            </div>}
                        {selection.settings.method === 'random'
                            && <div className={[styles.winner, styles.glow].join(' ')}>
                                <Spinner choices={selection.choices}
                                    winner={selection.result?.winners[0] || 0} />
                            </div>}
                    </div>
                    {(selection.result?.winners?.length || 0) > 1 && isHost(question)
                        && <TieBreaker method={tieBreakerMethod}
                            setMethod={setTieBreakerMethod}
                            start={startTieBreaker} />}
                </>}
                <AllResultsList question={question} />
            </div >
            <Feedback />
        </div>)
}

