import { useEffect, useId, useState } from "react";
import { Question } from "../../model/question";
import { setCookie } from "../../services/cookies";
import { Notifications } from "../../services/notifications";
import styles from "./NotifyMe.module.scss";

export default function NotifyMe({ question }: {
    question: Question
}) {
    const notifyCheckedId = useId();
    const [label, setLabel] = useState<string>('');
    const [notify, setNotify] = useState<boolean>(Notifications.isEnabled);
    const [canEnableNotifications, setCanEnableNotifications] = useState<boolean>(Notifications.canEnable);

    useEffect(() => {
        switch (question.state) {
            case 'nominating': setLabel('Notify me when voting starts'); break;
            case 'selecting': setLabel('Notify me when results are ready'); break;
        }
    }, [question]);

    useEffect(() => {
        const elmt = document.getElementById(notifyCheckedId) as HTMLInputElement | undefined;
        if (elmt) {
            elmt.indeterminate = false;
            elmt.checked = notify;
        }
        setCookie('notify', notify ? '1' : '0');
    }, [notify]);

    function updateNotify() {
        const elmt = document.getElementById(notifyCheckedId) as HTMLInputElement | undefined;
        if (elmt) {
            elmt.indeterminate = false;
            let checked = elmt.checked;
            if (checked) {
                if (Notifications.isAllowed) {
                    Notifications.enable();
                    elmt.checked = true;
                    setNotify(true);
                    setCanEnableNotifications(true);
                } else if (Notifications.canEnable) {
                    elmt.indeterminate = true;
                    Notifications.requestAllow().then(permission => {
                        if (permission !== 'default') {
                            updateNotify();
                        } else {
                            elmt.indeterminate = false;
                            elmt.checked = false;
                            setNotify(false);
                        }
                    });
                } else {
                    elmt.checked = false;
                    setNotify(false);
                    setCanEnableNotifications(false);
                }
            } else {
                Notifications.disable();
                elmt.checked = false;
                setNotify(false);
                setCanEnableNotifications(true);
            }
        }
    }

    return canEnableNotifications && question.state !== 'results' ?
        <div className={styles.notificationSettings}>
            <div className={styles.checkboxWrapper}>
                <input id={notifyCheckedId}
                    type="checkbox"
                    className={styles.checkbox}
                    defaultChecked={notify}
                    onChange={updateNotify} />
            </div>
            <label htmlFor={notifyCheckedId} className={styles.finePrint}>{label}</label>
        </div> : null;
}