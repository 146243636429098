import styles from './ToggleButtons.module.scss';

export default function ToggleButtons({ active, setActive, options, size }: {
    active: string,
    setActive: (active: string) => any,
    options: {
        key: string,
        label: string,
        imgSrc?: string,
        tag?: string,
    }[],
    size?: 'medium' | 'small';
}) {
    function getSizeClass() {
        if (size === 'small') {
            return styles.small;
        }
        return '';
    }

    return <div className={[styles.toggleButtonRow, getSizeClass()].join(' ')}>
        {options.map(option =>
            <button
                key={option.key}
                className={[styles.button, active === option.key ? styles.selected : styles.unselected].join(' ')}
                onClick={() => setActive(option.key)}>
                <span>{option.label}</span>
                {option.tag &&
                    <span className={styles.numberTag}>{option.tag}</span>}
                {option.imgSrc &&
                    <img style={{
                        maskImage: `url('${option.imgSrc}')`,
                        WebkitMaskImage: `url('${option.imgSrc}')`,
                    }} />
                }
            </button>)}
    </div>
}