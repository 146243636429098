import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/home/App';
import './index.css';
import reportWebVitals from './reportWebVitals';

const registerServiceWorker = async () => {
  if ("serviceWorker" in navigator) {
    try {
      const registration = await navigator.serviceWorker.register("/serviceWorker.js", { scope: "/", });
      console.log('Service Worker registration succeeded');
    } catch (error) {
      console.error(`Service Worker registration failed with: ${error}`);
    }
  }
};

// …

registerServiceWorker();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
