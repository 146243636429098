import { QuestionLocation } from "../model/question";

const url = 'https://maps.googleapis.com/maps/api/geocode/json';

export async function getLocationDetails(lat: number, long: number): Promise<QuestionLocation> {
    const result = await fetch(`${url}?latlng=${lat},${long}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`);
    const resultObj = await result.json();
    const place = resultObj.results[0] as google.maps.places.PlaceResult;
    return {
        id: place.place_id,
        formattedAddress: getLocationString(place),
        location: place.geometry?.location,
    };
}

export function getLocationString(place: google.maps.places.PlaceResult): string | undefined {
    const components = place.address_components;
    if (components) {
        const locality = components.find(l => l.types.indexOf('locality') > -1);
        const region = components.find(l => l.types.indexOf('administrative_area_level_1') > -1);
        const country = components.find(l => l.types.indexOf('country') > -1);
        return `${locality?.short_name}, ${region?.short_name}, ${country?.short_name}`;
    }
    return undefined;
}