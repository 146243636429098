import { KeyboardSensor, PointerSensor, TouchSensor } from "@dnd-kit/core";
import type { KeyboardEvent, PointerEvent, TouchEvent } from "react";

/**
 * An extended "PointerSensor" that prevent some
 * interactive html element(button, input, textarea, select, option...) from dragging
 */
export class CustomPointerSensor extends PointerSensor {
    static activators = [
        {
            eventName: "onPointerDown" as any,
            handler: ({ nativeEvent: event }: PointerEvent) => {
                if (
                    !event.isPrimary ||
                    event.button !== 0 ||
                    isInteractiveElement(event.target as Element)
                ) {
                    return false;
                }

                return true;
            },
        },
    ];
}

export class CustomTouchSensor extends TouchSensor {
    static activators = [
        {
            eventName: "onTouchStart" as any,
            handler: ({ nativeEvent: event }: TouchEvent) => {
                if (isInteractiveElement(event.target as Element)) {
                    return false;
                }

                return true;
            },
        },
    ];
}

export class CustomKeyboardSensor extends KeyboardSensor {
    static activators = [
        ...KeyboardSensor.activators,
        {
            eventName: "onKeyDown" as any,
            handler: ({ nativeEvent: event }: KeyboardEvent) => {
                if (event.key === 'Enter' || event.key === ' ') {
                    if (isInteractiveElement(event.target as Element)) {
                        return false;
                    }

                    return true;
                }
                return false;
            },
        },
    ];


}

function isInteractiveElement(element: Element | null) {
    const interactiveElements = [
        "button",
        "input",
        "textarea",
        "select",
        "option",
    ];
    if (
        element?.tagName &&
        interactiveElements.includes(element.tagName.toLowerCase())
    ) {
        return true;
    }

    return false;
}