import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { KeyboardEvent } from 'react';
import { Choice, ParticipantResult } from '../../model/question';
import styles from './SortableItem.module.scss';

export function SortableItem({ isActive, choice, index, numChoices, participant, activeChoice, disabled, moveUp, moveDown }:
    {
        isActive?: boolean,
        choice: Choice,
        index?: number,
        numChoices?: number,
        participant?: ParticipantResult | undefined,
        activeChoice?: Choice,
        disabled?: boolean,
        moveUp?: (choice: Choice) => any,
        moveDown?: (choice: Choice) => any,
    }) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({
        id: choice.text, disabled: disabled, animateLayoutChanges: () => false,
    });

    function keyDown(event: KeyboardEvent) {
        if (event.key === 'ArrowUp') {
            moveUp && moveUp(choice);
            event.preventDefault();
        } else if (event.key === 'ArrowDown') {
            moveDown && moveDown(choice);
            event.preventDefault();
        }
    }

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const classNames = [styles.option, choice === activeChoice && styles.activeOption];

    return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners}
            className={styles.sortable}
            id={`option_${choice.text}`}
            onKeyDown={keyDown}>
            <div className={styles.optionWrapper}>
                <div id={choice.text}
                    className={classNames.join(' ')}>
                    <img className={[styles.dragIcon, (participant?.submitted || isActive) && styles.disableDrag].join(' ')} />
                    <span>{choice.text}</span>
                    {(!participant?.submitted && !isActive) ?
                        <div className={styles.arrows}>
                            <button className={styles.button}
                                id={`btnUp_${choice.text}`}
                                disabled={index === 0}
                                onClick={() => moveUp && moveUp(choice)}>▲</button>
                            <button className={styles.button}
                                id={`btnDown_${choice.text}`}
                                disabled={index === (numChoices || 0) - 1}
                                onClick={() => moveDown && moveDown(choice)}>▼</button>
                        </div>
                        : <div className={styles.arrows}></div>}
                </div>
            </div>
        </div>
    );
}