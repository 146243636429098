import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Question } from "../../model/question";
import FeedbackPopup from "../common/FeedbackPopup";
import Title from "../common/Title";
import Collaborate from "./Collaborate";
import styles from './CollaborateWrapper.module.scss';

export default function CollaborateWrapper() {
    const { code } = useParams();
    const [question, setQuestion] = useState<Question | undefined>(undefined);
    const [error, setError] = useState<string | undefined>(undefined);
    const [recoverable, setRecoverable] = useState<boolean>(false);
    const [feedbackCode, setFeedbackCode] = useState<string | undefined>(undefined);
    const mounted = useRef(false);

    useEffect(() => {
        if (!mounted.current) {
            loadQuestion();
            mounted.current = true;
        }
    }, []);

    const loadQuestion = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/questions/${code}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                });
            if (response.status !== 200) {
                setError('Question not found.');
                setQuestion(undefined);
                if (response.status >= 400 && response.status < 500) {
                    setRecoverable(false);
                } else if (response.status >= 500) {
                    setRecoverable(true);
                }
            } else {
                const respObj = await response.json();
                if (respObj.error) {
                    throw new Error(respObj.error);
                }
                const question = respObj.question as Question;
                setQuestion(question);
                setError(undefined);
            }
        } catch (err) {
            setError((err as Error).message);
            setQuestion(undefined);
            setRecoverable(true);
        }
    }

    async function gatherFeedback() {
        setFeedbackCode(code);
    }

    return (
        <div className={styles.collaborate}>
            <FeedbackPopup code={feedbackCode} hide={() => setFeedbackCode(undefined)} />
            <Title gatherFeedback={gatherFeedback} />
            <div className={styles.body}>
                {error
                    ? <div className={styles.error}>
                        <div>Oops! Something went wrong.</div>
                        <div>{error}</div>
                        <div>
                            {recoverable &&
                                <>
                                    <a href={document.location.origin + document.location.pathname}>Reload to try again</a>
                                    <span> or </span>
                                </>}
                            <a href="/">Go home</a>
                        </div>
                    </div>
                    : <div>{!question
                        ? <div>
                            <div className={styles.loading}>Loading...</div>
                        </div>
                        : <Collaborate initialQuestion={question}
                            error={error}
                            setError={(error, recoverable) => {
                                setError(error);
                                setRecoverable(recoverable || false);
                                if (error) {
                                    setQuestion(undefined);
                                }
                            }}
                            gatherFeedback={gatherFeedback} />}
                    </div>}
            </div>
        </div>);
}