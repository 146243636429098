import { Place } from "../../model/question";
import styles from './PlaceCard.module.scss';

export default function PlaceCard({ place, active, buttonText, buttonClicked, cardClicked }: {
    place: Place,
    active?: boolean,
    buttonText?: string,
    buttonClicked?: (place: Place) => any,
    cardClicked?: (place: Place) => any,
}) {
    function getPriceLevelStrings(priceLevel?: google.maps.places.PriceLevel | null): string[] {
        if (!priceLevel) {
            return [];
        }
        switch (priceLevel?.toString()) {
            case 'PRICE_LEVEL_FREE': return ['Free', ''];
            case 'PRICE_LEVEL_INEXPENSIVE': return ['Inexpensive', '$'];
            case 'PRICE_LEVEL_MODERATE': return ['Moderate', '$$'];
            case 'PRICE_LEVEL_EXPENSIVE': return ['Expensive', '$$$'];
            case 'PRICE_LEVEL_VERY_EXPENSIVE': return ['Very Expensive', '$$$$'];
            default: return [];
        }
    }

    const style = { "--rating": place.rating } as React.CSSProperties;
    const priceLevel = getPriceLevelStrings(place.priceLevel);
    return <div className={[styles.place, active ? styles.active : undefined].join(' ')}
        onClick={() => cardClicked && cardClicked(place)}>
        <div>
            <div className={styles.name}>{place.displayName}</div>
            <div className={styles.row}>
                <span>{place.rating}</span>
                <span className={styles.stars} title={place.rating?.toString() || ''}>
                    <span className={styles.empty}>
                        <span className={styles.star} />
                        <span className={styles.star} />
                        <span className={styles.star} />
                        <span className={styles.star} />
                        <span className={styles.star} />
                    </span>
                    <span className={styles.filled}
                        style={style}>
                        <span className={styles.star} />
                        <span className={styles.star} />
                        <span className={styles.star} />
                        <span className={styles.star} />
                        <span className={styles.star} />
                    </span>
                </span>
                <span>({place.userRatingCount})</span>
                <span title={priceLevel[0] || ''}>{priceLevel[1]}</span>
            </div>
            <div className={styles.address}>{place.formattedAddress}</div>
        </div>
        {buttonText &&
            <button className={styles.button}
                disabled={!buttonClicked}
                onClick={() => buttonClicked && buttonClicked(place)}>
                {buttonText}
            </button>
        }
    </div>
}