import { useEffect, useRef, useState } from 'react';
import { Place, Question, Selection, isHost } from '../../model/question';
import { getCookie } from '../../services/cookies';
import { QuestionClient } from '../../services/questionClient';
import Pill from '../common/pill/Pill';
import RestaurantSearchPopup from '../restaurant/RestaurantSearchPopup';
import styles from './LocationNominate.module.scss';

export default function Nominate({ question }: {
    question: Question,
}) {
    const [user] = useState(getCookie('user'));
    const [host] = useState(isHost(question));
    const [selection, setSelection] = useState<Selection | undefined>(undefined);
    const [showSearch, setShowSearch] = useState(false);

    const didUnmount = useRef(false);
    const { questionClient } = QuestionClient.useWebSocket(question.code, undefined, didUnmount.current);

    useEffect(() => {
        return () => {
            didUnmount.current = true;
        };
    }, []);

    useEffect(() => {
        setSelection(question.selections.find(sel => sel.active));
    }, [question]);

    useEffect(() => {
        if (selection && !selection.choices.length && isHost(question)) {
            setShowSearch(true);
        }
    }, [selection]);

    function addClicked(place: Place) {
        if (place.displayName) {
            questionClient.addChoice(place.displayName, place);
        }
    }

    function removeClicked(place: Place) {
        if (place.displayName) {
            questionClient.deleteChoice(place.displayName);
        }
    }

    return <>
        <RestaurantSearchPopup show={showSearch}
            settings={question.settings}
            choices={selection?.choices}
            addPlace={addClicked}
            hide={() => setShowSearch(false)} />
        <div className={styles.nominate}>
            <div className={styles.contentBox}>
                {selection?.choices &&
                    <>
                        <div className={styles.contentHeader}>
                            Current Nominations:
                        </div>
                        {!!selection?.choices.length &&
                            <div>
                                {selection?.choices.map((choice, index) => {
                                    const place = choice.place;
                                    if (place) {
                                        return <Pill key={index}
                                            content={choice.text}
                                            clickHint={(host || choice.user === user) ? 'x' : undefined}
                                            click={() => removeClicked(place)} />
                                    } else {
                                        return null;
                                    }
                                })}
                            </div>}
                        {!selection?.choices.length &&
                            <div className={styles.noSuggestions}>None yet. Add some choices for your group to select from.</div>}
                    </>}
                <div className={styles.centered}>
                    <button className={styles.mapButton} onClick={() => setShowSearch(true)}>
                        <span>Find Restaurants</span>
                        <span className={styles.markerImage} />
                    </button>
                </div>
            </div>
        </div>
    </>;
}

