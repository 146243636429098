import { useEffect, useId } from 'react';
import styles from './RankTotals.module.scss';

export function RankTotals({ first, second, third, centered }: {
    first: number,
    second: number,
    third: number,
    centered?: boolean,
}) {
    const firstId = useId();
    const secondId = useId();
    const thirdId = useId();

    function getMargin(num: number) {
        // make sure total width <= 45
        if (num <= 3) return '-5px';   // 25 + (10 * 2) = 45
        if (num <= 4) return '-9px';   // 25 + (6 * 3) = 43
        if (num <= 5) return '-10px';   // 25 + (5 * 4) = 45
        if (num <= 6) return '-11px';   // 25 + (4 * 5) = 45
        return '-12px';                 // 25 + (3 * 6) = 43
    }

    useEffect(() => {
        const firstElmt = document.getElementById(firstId);
        const secondElmt = document.getElementById(secondId);
        const thirdElmt = document.getElementById(thirdId);
        firstElmt?.style.setProperty('--firstMargin', getMargin(first || 0));
        secondElmt?.style.setProperty('--secondMargin', getMargin(second || 0));
        thirdElmt?.style.setProperty('--thirdMargin', getMargin(third || 0));
    }, [first, second, third]);

    return <div className={[styles.rankTotals, centered ? styles.centered : ''].join(' ')}>
        {first > 0 && <span id={firstId}>
            {[...Array(Math.min(first, 7))].map((_, i) => <img key={i} className={styles.firstIcon} />)}
            <span>x {first}</span>
        </span>}
        {second > 0 && <span id={secondId}>
            {[...Array(Math.min(second, 7))].map((_, i) => <img key={i} className={styles.secondIcon} />)}
            <span>x {second}</span>
        </span>}
        {third > 0 && <span id={thirdId}>
            {[...Array(Math.min(third, 7))].map((_, i) => <img key={i} className={styles.thirdIcon} />)}
            <span>x {third}</span>
        </span>}
    </div>
}