import { useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { useNavigate } from "react-router-dom";
import { Question, QuestionLocation, QuestionSettings } from "../../model/question";
import { getLocationDetails, getLocationString } from "../../services/geolocation";
import ToggleButtons from "../common/ToggleButtons";
import styles from './RestaurantMainCard.module.scss';

export default function RestaurantMainCard() {
    const [radius, setRadius] = useState<number | ''>(10);
    const [unit, setUnit] = useState<'miles' | 'kms'>('miles');
    const [position, setPosition] = useState<GeolocationCoordinates | undefined>(undefined);
    const [locationText, setLocationText] = useState<string>('');
    const [location, setLocation] = useState<QuestionLocation>();
    const [codeText, setCodeText] = useState<string>('');
    const navigate = useNavigate()

    useEffect(() => {
        getPosition();
        onLocationChanged();  // initialize questionText in case field is not empty on load
        onCodeChanged();
        setUpGoogleMapsScriptCallback();
    }, []);

    useEffect(() => {
        if (position) {
            getLocationDetails(position.latitude, position.longitude).then(loc => {
                setLocation(loc);
                setLocationText(loc.formattedAddress || '');
            });
        } else {
            setLocation(undefined);
            setLocationText('');
        }
    }, [position]);

    function setUpGoogleMapsScriptCallback() {
        (window as any).initializeLocationSearch = function () {
            const locationSearch = document.getElementById('locationText') as HTMLInputElement;
            const autoComplete = new google.maps.places.Autocomplete(locationSearch, {
                types: ['(regions)'],
            });
            autoComplete.addListener('place_changed', () => {
                const place = autoComplete.getPlace();
                setLocation({
                    id: place.place_id,
                    formattedAddress: place.formatted_address,
                    location: place.geometry?.location,
                });
                const loc = getLocationString(place);
                setLocationText(loc || '');
            });
        }
    }

    async function getPosition() {
        navigator.geolocation.getCurrentPosition(position => {
            setPosition(position.coords);
        }, null, {
            enableHighAccuracy: true,
        });
    }

    const onWithinChanged = function (value: string) {
        const parsed = parseInt(value);
        const num = Number.isNaN(parsed) ? '' : parsed;
        setRadius(num);
    }

    const onLocationChanged = function () {
        const text = (document.getElementById('locationText') as HTMLInputElement)?.value.trim();
        setLocationText(text);
    }

    const onCodeChanged = function () {
        const code = (document.getElementById('codeText') as HTMLInputElement)?.value.trim();
        setCodeText(code);
    }

    function keyUpQuestion(e: React.KeyboardEvent) {
        if (e.key === 'Enter') {
            submitQuestion();
        }
    }

    function keyUpCode(e: React.KeyboardEvent) {
        if (e.key === 'Enter') {
            submitCode();
        }
    }

    const submitQuestion = async function () {
        if (locationText) {
            const settings: QuestionSettings = {
                type: 'restaurant',
                location: location,
                radius: radius || undefined,
                unit: unit,
            }
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/questions`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                    body: JSON.stringify({
                        questionText: 'Where should we go to eat?',
                        settings: settings,
                    }),
                });
            if (response.status !== 200) {
                throw new Error(response.statusText);
            }
            const respObj = await response.json();
            if (respObj.error) {
                throw new Error(respObj.error);
            }
            const question = respObj.question as Question;
            document.location = `/q/${question.code}`;  // todo: switch to navigate
        }
    }

    const submitCode = async function () {
        if (codeText.length === 6) {
            navigate(`/q/${codeText.toLowerCase()}`)
        }
    }

    return <>
        <Helmet
            script={[
                { src: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&loading=async&libraries=places&callback=initializeLocationSearch` }
            ]} />
        <div className={styles.mainCard}>
            <div className={styles.mainTitle}>
                <div>
                    Where should we go to eat?
                </div>
            </div>
            <div className={[styles.row, styles.withinRow].join(' ')}>
                <div>Within</div>
                <input className={styles.withinField}
                    type="number"
                    id="withinNumberText"
                    min={5}
                    max={90}
                    step={5}
                    value={radius.toString()}
                    onFocus={e => e.target.select()}
                    onChange={e => onWithinChanged(e.target.value)} />
                <ToggleButtons
                    active={unit}
                    setActive={(active) => setUnit(active as 'miles' | 'kms')}
                    size={'small'}
                    options={[
                        { key: 'miles', label: 'miles' },
                        { key: 'kms', label: 'kms' },
                    ]} />
                <div>of</div>
            </div>
            <div className={styles.row}>
                <input className={styles.locationField}
                    autoFocus
                    autoComplete='one-time-code'
                    type="text"
                    id="locationText"
                    placeholder="Enter a city or postal code"
                    defaultValue={locationText}
                    maxLength={256}
                    onFocus={e => e.target.select()}
                    onChange={() => onLocationChanged()}
                // onKeyUp={keyUpQuestion} 
                />
                <button className={styles.button}
                    id="startButton"
                    disabled={!location}
                    onClick={submitQuestion}>
                    Start!
                </button>
            </div>
            <div className={styles.row}>
                (Or enter your invitation code)
            </div>
            <div className={styles.row}>
                <input className={styles.codeField}
                    autoComplete='one-time-code'
                    type="text"
                    id="codeText"
                    maxLength={6}
                    onFocus={e => e.target.select()}
                    onChange={() => onCodeChanged()}
                    onKeyUp={keyUpCode} />
                <button className={styles.button}
                    type="button"
                    id="codeButton"
                    disabled={codeText.length !== 6}
                    onClick={submitCode}>
                    Go
                </button>
            </div>
        </div>
    </>
}