import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Participant } from '../../model/question';
import { setCookie } from '../../services/cookies';
import { QuestionClient } from '../../services/questionClient';
import Popup from "../common/Popup";
import styles from './RenameParticipantPopup.module.scss';

export default function RenameParticipantPopup({ user, hide }: {
    user?: Participant,
    hide: () => void,
}) {
    const { code } = useParams();
    const didUnmount = useRef(false);
    const { questionClient } = QuestionClient.useWebSocket(code, undefined, didUnmount.current);

    useEffect(() => {
        return () => {
            didUnmount.current = true;
        };
    }, []);

    function keyUp(e: React.KeyboardEvent) {
        if (e.key === 'Enter') {
            editName();
        }
    }

    async function editName() {
        if (user) {
            const name = (document.getElementById('textValue') as HTMLInputElement).value;
            if (name && name !== user.name) {
                setCookie('name', name);
                questionClient.renameParticipant(name);
            }
        }
        hide();
    }

    return user
        ? <Popup
            hide={hide}
            caption={`What's your name?`}>
            <div className={styles.scrollDiv}>
                <input type="text"
                    autoFocus
                    id="textValue"
                    onFocus={e => e.target.select()}
                    onKeyUp={keyUp}
                    defaultValue={user.name || ''} />
                <input type="button"
                    value="Save"
                    onClick={editName} />
            </div>
        </Popup >
        : null;
}