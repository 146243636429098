import { useEffect, useId } from 'react';
import styles from './VoteBar.module.scss';

export default function VoteBar({ votes, vetoes, numParticipants }: {
    votes: number,
    vetoes: number,
    numParticipants: number,
}) {
    const voteBarId = useId();
    const vetoBarId = useId();

    useEffect(() => {
        document.getElementById(voteBarId)?.style.setProperty('--value', (votes * 100 / numParticipants).toString() + '%');
        document.getElementById(vetoBarId)?.style.setProperty('--value', (vetoes * 100 / numParticipants).toString() + '%');
    }, [votes, vetoes, numParticipants]);

    return <div className={styles.voteBar}>
        <div className={styles.votes} id={voteBarId}> </div>
        <div className={styles.vetoes} id={vetoBarId}> </div>
    </div>;
}