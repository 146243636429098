import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Question } from "../../model/question";
import { getLocationDetails } from '../../services/geolocation';
import Popup from '../common/Popup';
import styles from './AiSuggestionPopup.module.scss';

export default function AiSuggestionPopup({ question, addSuggestion, hide }: {
    question?: Question,
    addSuggestion: (suggestion: string) => any;
    hide: () => void,
    children?: never[],
}) {
    const { code } = useParams();
    const [needsPosition, setNeedsPosition] = useState(false);
    const [position, setPosition] = useState<GeolocationCoordinates | undefined>(undefined);
    const [city, setCity] = useState<string>('');
    const [suggestions, setSuggestions] = useState<string[]>([]);
    const [visible, setVisible] = useState<number>(0);
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (question) {
            if (needsPosition && !position) {
                getPosition();
            } else {
                loadSuggestions();
            }
        } else {
            // reset state
            // setNeedsPosition(false); // don't reset
            setMessage('Loading suggestions...');
            setLoading(true);
            setSuggestions([]);
            setVisible(0);
        }
    }, [question, needsPosition, position]);

    useEffect(() => {
        if (suggestions && suggestions.length > 0) {
            setVisible(suggestions.length);
        }
    }, [suggestions]);

    useEffect(() => {
        if (visible === 0) {
            // all suggestions have been added
            // hide the modal
            setTimeout(hide, 1000);
        }
    }, [visible]);

    useEffect(() => {
        if (position) {
            getLocationDetails(position.latitude, position.longitude).then(details => setCity(details?.formattedAddress || ''));
        } else {
            setCity('');
        }
    }, [position]);

    async function getPosition() {
        const oldMessage = message;
        if ((await navigator.permissions.query({ name: 'geolocation' })).state !== 'granted') {
            setMessage(`Hmm... we'll need to know where you are to generate suggestions. Please enable location permissions for this site.`);
        }
        navigator.geolocation.getCurrentPosition(position => {
            setMessage(oldMessage);
            setPosition(position.coords);
        }, () => {
            setMessage(`Hmm... we'll need to know where you are to generate suggestions. Please enable location permissions for this site.`);
        });
    }

    async function loadSuggestions() {
        console.log(location);
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/questions/${code}/generateSuggestions`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify(needsPosition ? {
                    position: {
                        latitude: position?.latitude,
                        longitude: position?.longitude,
                    }
                } : {}),
            });
        const responseObj = await response.json();
        if (responseObj.needsPosition) {
            setNeedsPosition(true);
        } else if (responseObj.suggestions) {
            if (!Array.isArray(responseObj.suggestions) || responseObj.suggestions.length === 0) {
                setSuggestions([]);
                setLoading(false);
                setMessage(`Sorry! We couldn't generate suggestions right now. :(`);
                return;
            }

            setMessage('How about these ideas?');
            setLoading(false);
            setSuggestions(responseObj.suggestions);
        }
    }

    function add(suggestion: string) {
        addSuggestion(suggestion);
        const div = document.getElementsByClassName(styles.suggestions)[0] as HTMLDivElement;
        const suggDiv = div.children[suggestions.indexOf(suggestion)] as HTMLDivElement;
        suggDiv.classList.add(styles.hidden);
        console.log(visible);
        setVisible(previous => previous - 1);
    }

    return question
        ? <Popup
            hide={hide}
            caption={'AI Suggestions'}>
            <div className={styles.scrollDiv}>
                {message &&
                    <div className={styles.message}>
                        <div>{message}</div>
                        {needsPosition && city &&
                            <div className={styles.city}>near {city}</div>}
                        {loading &&
                            <img src="/icons/sparkle.svg" />}
                    </div>}
                <div className={styles.suggestions}>
                    {suggestions.map(sugg =>
                        <div key={sugg}>
                            <div>{sugg}</div>
                            <input type="button"
                                className={styles.smallButton}
                                value="Add"
                                onClick={() => add(sugg)}
                            />
                            <img src="/icons/sparkle.svg" />
                        </div>)}
                </div>
            </div>
        </Popup >
        : null;
}